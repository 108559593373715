// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isAuth: false,
  isSubmitting: false,
  isFetching: true,
  selectedLang: "en",
  userParams: {},
  userInfo: {},
  toaster: {
    open: false,
    message: "test",
    severity: "success",
  },
  resetPass: false,
  loginPopup: false,
  registerPopup: false,
  forgotPassPopup: false,
  changePassPopup: false,
  manageAddressPopup: false,
  registerVerify: false,
  deleteAccountVerify: false,
  dialog: {
    open: false,
    message: "TEST",
    title: "TEST",
  },
  pageData: {},
  setting: {},
  faqData: {},
  bankData: [],
  cardData: {},
  contactUs: {},
  homePageData: {},
  changePassInfo: {},
  userProfileInfo: {},
  emailStatus: false,
  pushStatus: false,
  language: "",
  addUserAddress: {},
  getuserdata: {},
  deleteuseradd: {},
  defaultuseradd: {},
  updateuseradd: {},
  createpost: {},
  publicProfile: {},
  getCategoriesData: [],
  getBrandData: [],
  getModalData: [],
  submitPost: [],
  getConditionData: [],
  getAddressListData: [],
  postPreview: [],
  paymentData: {},
  device_token: "",
  socialParamsPopup: false,
  parentCategory: [],
};
