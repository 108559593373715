import { React, useEffect, lazy, useState } from "react";
import { Container, Row, Col, Button, Modal, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import { Link, useHistory, useLocation } from "react-router-dom";
import CustomTabs from "../../../Component/Tabs/Tabs";
import { useTranslation } from "react-i18next";
import {
  joinRoom,
  initiateSocket,
  disconnectSocket,
  cancelOfferRequest,
  acceptOfferRequest,
  subscribeToSuccessfully,
  subscribeToError,
} from "../../../utils/Socket";

import {
  archivePost,
  fetchPostList,
  getBuying,
  getEditPost,
  getSubscriptionList,
  getRemainingBoost,
  applyBoost,
  getCard,
} from "../../../actions/UserAction";
import LottiLoader from "../../../Component/lottiAnimation/LottiLoader";
import { textTruncate } from "../../../Component/TextTruncate/textTruncate";
import Pagination from "@mui/material/Pagination";
import { Divider } from "@mui/material";
import parse from "html-react-parser";
import Dialog from "@material-ui/core/Dialog";
import {
  crose,
  checkmark_bullet,
  right_arrow,
  themeboost,
  card,
  Cash,
} from "../../../assets/images/index";
import { TextFormate } from "../../../Component/TextFormate/TexFormate";
import { getNumber } from "../../../utils/helpers";
import { loadToasterData } from "../../../actions/baseActions";
import { getPostDataOffer } from "../../../actions/HomeAction";
import CryptoJS from "crypto-js";
import axios from "axios";
import { useFormik } from "formik";
import config from "./../../../Component/Urway/index.json";
import moment from "moment";
import MarkAsSoldModal from "./MarkAsSoldModal";
import { makeStyles } from "@material-ui/core/styles";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
const CounterOffer = lazy(() => import("../../Chat/counterOffer"));
const CounterOfferSeller = lazy(() => import("./counterOffer"));

//const OfferList = lazy(() => import("./offerList"));
const generateHashSHA256 = (hashSequence) => {
  let hash = CryptoJS.SHA256(hashSequence).toString();
  return hash;
};
const emailRegexp = new RegExp(
  "^[a-zA-Z0-9]+([a-zA-Z0-9_.\\$&-]+)*@[a-zA-Z0-9]+([a-zA-Z0-9_.\\$&-]+)*\\.([a-zA-Z0-9]{2,4})$"
);
const ipRegexp = new RegExp(
  "^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
);
const amountRegexp = new RegExp("^[0-9]?((.[0-9]+)|([0-9]+(.[0-9]+)?))$");

let resParameter = {};

function Manage(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  // All Selectors
  const {
    GET_USER_PROFILE,
    GET_POST_DATA,
    POST_LIST_PAGINATION,
    LOADING,
    GET_BUYING_DATA,
    userInfo,
    selectedLang,
  } = useSelector((state) => ({
    GET_USER_PROFILE: state?.sidebarReducer?.userProfileData,
    GET_POST_DATA: state?.userReducer?.postData,
    POST_LIST_PAGINATION: state?.userReducer?.postListPagination,
    LOADING: state?.userReducer?.loadingStatus,
    GET_BUYING_DATA: state?.userReducer?.buyingData,
    selectedLang: state.selectedLang,
    userInfo: state.userInfo,
  }));
  // console.log("PostData->>>>>>>>", GET_POST_DATA);
  // All state
  const customTab = [
    { eventKey: "selling", title: t("SELLING") },
    { eventKey: "buying", title: t("BUYING") },
  ];
  const [activeDefaultTab, setActiveDefaultTab] = useState("selling");
  const [page, setPage] = useState(1);
  const [showMarkAsSoldModal, setShowMarkAsSoldModal] = useState(false);
  const [markAsSoldData, setMarkAsSold] = useState("");

  const onSelectTab = (e) => {
    history.push({
      pathname: "/manage",
    });
    setActiveDefaultTab(e);
    if (e === "selling") {
      dispatch(fetchPostList());
    }
    if (e === "buying") {
      dispatch(getBuying());
    }
  };

  useEffect(() => {
    if (activeDefaultTab === "selling") {
      dispatch(fetchPostList());
    }
    if (activeDefaultTab === "buying") {
      dispatch(getBuying());
    }
  }, []);

  const handelArchivePost = (id) => {
    const postData = { id: id };
    dispatch(archivePost(postData));
  };

  const fetchEditPostData = (id, text) => {
    dispatch(getEditPost(id, history, text));
  };

  const pagination = (e, value) => {
    const params = new URLSearchParams(location.search);
    params.set("page", value);
    history.push({
      pathname: "/manage",
      search: "?" + params,
    });
    setPage(value);
    if (activeDefaultTab === "selling") {
      dispatch(fetchPostList());
    }
    if (activeDefaultTab === "buying") {
      dispatch(getBuying());
    }
  };
  // Subscription
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (data) => {
    postIdBoost(data);
    setShowOfferList(false);
    setShow2(true);
    localStorage.setItem("post_id_subs", data);
  };
  /* Boost Plans */
  useEffect(() => {
    const request = {};
    dispatch(getSubscriptionList(request, returnDataSubs));
  }, [dispatch]);
  const [getSubscriptionData, setSubscriptionData] = useState("");
  const returnDataSubs = (data) => {
    setSubscriptionData(data);
    if (data && data.item) {
      setActiveState(data.item[0]._id);
      localStorage.setItem("boost_id", data.item[0]._id);
      localStorage.setItem("subscription_amount", data.item[0].amount);
    }
  };
  // console.log(localStorage.getItem("subscription_amount"));
  //  console.log(localStorage.getItem("boost_id"));
  const [getActiveState, setActiveState] = useState();
  const [getPostIdBoost, postIdBoost] = useState("");
  const handleActiveState = (data, amount) => {
    setActiveState(data);
    localStorage.setItem("boost_id", data);
    localStorage.setItem("subscription_amount", amount);
  };

  const handleBuySubscription = () => {
    setShow2(false);
    setShowModelCard(true);
    //   history.push("/subscription");
  };
  /* Boost Plans */
  // How this Works
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => {
    setShow4(false);
    setShow2(true);
  };
  const handleShow4 = () => {
    setShow2(false);
    setShow4(true);
  };

  /* Remaining Boost */

  const [showModelR, setShowModelR] = useState(false);
  const handleCloseR = () => {
    setShowModelR(false);
    setShow2(true);
  };
  const remainingBoost = () => {
    setShow2(false);
    setShowModelR(true);
  };
  useEffect(() => {
    const request = {};
    dispatch(getRemainingBoost(request, returnDataRemaining));
  }, [dispatch, showModelR]);
  const [remainingBoostData, setRemainingBoost] = useState("");
  const returnDataRemaining = (data) => {
    setRemainingBoost(data);
  };
  //console.log(remainingBoostData);
  const [getRemainingActiveState, setRemainingActiveState] = useState();
  const handleActiveRemaining = (data) => {
    setRemainingActiveState(data._id);
  };
  /* Remaining Boost */
  const handleRemainingBoostApply = () => {
    if (!getRemainingActiveState) {
      dispatch(loadToasterData(t("PLEASE_SELECT_BOOST"), "error", true));
    } else {
      var post_id = localStorage.getItem("post_id_subs");
      var boot_id = getRemainingActiveState;
      const request = { post_id, boot_id };
      //console.log(request);
      dispatch(applyBoost(request));
      /*setShow2(false);
      setShow4(false);
      setShowModelR(false);*/
      window.location.reload();
    }
  };

  /* Card List */
  var cardModels = localStorage.getItem("card_model");
  const [showModelCard, setShowModelCard] = useState(false);
  const handleCloseCard = () => {
    setShowModelCard(false);
    setShow2(true);
  };
  /*const handleShowCard = () => {
    setShow2(false);
    setShowModelCard(true);
  };*/
  useEffect(() => {
    const request = {};
    dispatch(getCard(request, returnDataCard));
  }, [dispatch, setShowModelCard]);
  const [getCardList, setCardList] = useState("");
  const returnDataCard = (data) => {
    setCardList(data);
  };

  const [getCardActiveState, setCardActiveState] = useState();
  const handleActiveCard = (data) => {
    setCardActiveState(data._id);
    localStorage.setItem("card_token", data.cardToken);
  };

  const handleCardBoostApply = () => {
    if (localStorage.getItem("card_token") === null) {
      dispatch(loadToasterData(t("PLEASE_SELECT_CARD"), "error", true));
    } else {
      history.push("/subscription");
    }
  };
  /* Card List */
  /* Add Card */
  const [double, setDouble] = useState(false);
  let index = 0;
  let count = 0;
  let queryParam = "";
  let trackId = moment().unix();
  const initialValues = {
    firstName: userInfo.first_name,
    lastName: userInfo.last_name,
    address: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: userInfo.mobile_number,
    trackid: trackId.toString(),
    terminalId: config.terminalId,
    customerEmail: userInfo.email,
    action: "12",
    merchantIp: "111.93.58.10",
    password: config.password,
    currency: "SAR",
    country: "SA",
    transid: "",
    amount: "1",
    tokenOperation: "A",
    cardToken: "",
    tokenizationType: "1",
    requestHash: "",
    udf1: "",
    udf2: "http://192.168.1.175:3000",
    udf3: "",
    udf4: "",
    udf5: "",
  };

  // console.log("initialValues", initialValues);

  const validate = (values) => {
    let errors = {};
    // console.log("values : ", values);
    if (!values.country) {
      errors.errorCountry = "Country Code Is Required";
      // isDisabel=false;
    } else if ((values.country + "").length !== 2) {
      errors.errorCountry = "Enter Valid Country Code";
      // isDisabel=false;
    }
    if (!emailRegexp.test(values.customerEmail)) {
      errors.errorEmail = "Invalid email";
      // isDisabel=false;
    }
    if (!ipRegexp.test(values.merchantIp)) {
      errors.errorMerchantIp = "Invalid Merchant IP";
      // isDisabel=false;
    }
    if (!amountRegexp.test(values.amount)) {
      errors.errorAmount = "Invalid Amount";
      // isDisabel=false;
    }
    if (!values.trackid) {
      errors.errorTrackid = "Track ID Is Required";
      // isDisabel=false;
    }
    if (!values.currency) {
      errors.errorCurrency = "Currency Is Required";
      // isDisabel=false;
    } else if (values.currency.length < 3) {
      errors.errorCurrency = "Enter Valid Currency";
      // isDisabel=false;
    }
    if (!values.action) {
      errors.errorAction = "Please Select Action";
      // isDisabel=false;
    }
    if (values.action + "" === "12")
      if (values.tokenOperation + "" === "") {
        errors.errorTokenOperation = "Please Select Token Operation";
        // isDisabel=false;
      }
    if (
      (values.action + "" === "2") |
      (values.action + "" === "5") |
      (values.action + "" === "3") |
      (values.action + "" === "9") |
      (values.action + "" === "10") |
      (values.action + "" === "6")
    ) {
      if (!values.transid) {
        errors.errorTransid = "Transaction ID Is Required";
        // isDisabel=false;
      }
    }
    // if(!(onlyDigitRegexp.test(values.zipCode)))
    //     errors.errorZipCode="Enter Valid Postal Code"
    return errors;
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      localStorage.setItem("pageTypeSet", "manage");

      // if(!isDisabel)
      //     setDouble(false)

      setDouble(true);
      values.country = (values.country + "").toUpperCase();
      values.currency = (values.currency + "").toUpperCase();
      //console.log("dropdown value : ",values.action);
      localStorage.setItem("getCurrency", (values.currency + "").toUpperCase());
      localStorage.setItem("isDirectReceipt", "false");
      let hashSequence = generateHashSHA256(
        values.trackid +
          "|" +
          values.terminalId +
          "|" +
          config.password +
          "|" +
          config.merchantKey +
          "|" +
          values.amount +
          "|" +
          values.currency
      );

      values.requestHash = hashSequence;
      //console.log("values : ",values)
      axios
        .post(config.service_url, values)
        .then((res) => {
          //console.log("targetUrl "+res.data.targetUrl)
          //console.log(res.data)
          resParameter = res.data;
          //console.log("resParameter ",resParameter)
          if (resParameter.targetUrl + "" === "null") {
            for (let [key, value] of Object.entries(resParameter)) {
              index = ++index;
              //  console.log(`${key} ${value}`);
            }
            for (let [key, value] of Object.entries(resParameter)) {
              count = ++count;
              queryParam = queryParam + key + "=" + value;
              if (count < index) queryParam = queryParam + "&";
            }
            // console.log("index : ", index, queryParam);
            window.location.assign(
              window.location.origin.toString() + "/paymentStatus?" + queryParam
            );
            setDouble(true);
          } else {
            // handleAddCard(res.data);
            window.location.assign(
              res.data.targetUrl.replace("?", "") +
                "?paymentid=" +
                res.data.payid
            );
            setDouble(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validate,
  });

  /* Add Card */

  /* Chat Link */

  const handleChatView = (data) => {
    history.push(`/chat/${data}`);
  };
  const [show, setShow] = useState(false);
  const [getRoomId, setRoomId] = useState();
  const [getCounterData, setCounterData] = useState();
  const handleCounterView = (e, data) => {
    setCounterData(data);
    setRoomId(data.room);
    e.preventDefault();
    setShow(true);
  };

  /*Chat Link*/
  const toggleMarkAsSoldModal = (item) => {
    setShowOfferList(false);
    setShowMarkAsSoldModal(!showMarkAsSoldModal);
    setMarkAsSold(item);
  };
  const [showOfferList, setShowOfferList] = useState(false);
  const [getDataItem, setDataItem] = useState("");
  const [getViewItem, setViewItem] = useState("");

  const handleOfferList = (e, data, view) => {
    const request = {
      post_id: data._id,
    };
    setViewItem(view);
    dispatch(getPostDataOffer(request, returnDataOffer));
  };
  const returnDataOffer = (data) => {
    //  console.log(data);
    if (data.data.length === 0) {
      dispatch(
        loadToasterData(t("CURRENTLY_OFFERS_NOT_AVAILABLE"), "error", true)
      );
    } else {
      setDataItem(data);
      setShowOfferList(true);
    }
  };
  const handleCloseOffer = () => {
    setShowOfferList(false);
  };

  const handleChatClick = (e, data) => {
    //history.push(`/chat/${data}`);
  };
  /* Socket Connection */
  useEffect(() => {
    initiateSocket(userInfo._id);
    if (getDataItem && getDataItem.data.room) {
      joinRoom(getDataItem && getDataItem.data.room,userInfo._id);
    }
    return () => {
      disconnectSocket();
    };
  }, [getDataItem, userInfo._id]);
  /* Socket Connection */

  const handleAcceptOffer = (e, data, postId) => {
    // console.log(data);
    // console.log(postId);
    var request = {};
    request.room_id = data.room;
    request.user_id = userInfo._id;
    request.language = selectedLang;
    request.paymentId = "";
    request.acceptBy = "SELLER";
    request.paymentType = data.paymentMethod;
    request.cardId = "";
    //  console.log(request);
    acceptOfferRequest(request);
    setShowOfferList(false);
    //window.location.reload();
  };

  useEffect(() => {
    subscribeToSuccessfully((err, data) => {
      if (err) return;
      dispatch(loadToasterData(data.message, "success", true));
    });

    subscribeToError((err, data) => {
      if (err) return;
      dispatch(loadToasterData(data.message, "error", true));
    });
  }, []);
  const [showCounter, setShowCounter] = useState("");
  const [getOfferType, setOfferType] = useState(false);
  const [getCounterDataOffer, setOfferCounterData] = useState();
  const [getCounterDataPost, setPostCounterData] = useState();
  const counterOfferSeller = (e, data, post, type) => {
    e.preventDefault();
    setOfferCounterData(data);
    setPostCounterData(post);
    setOfferType(type);
    setShowCounter(true);
  };
  const handleCancelOffer = (e, item, postId) => {
    e.preventDefault();
    var request = {};
    request.room_id = item.room;
    request.user_id = userInfo._id;
    request.chat_message_id = item?.chat_message_id;
    request.language = selectedLang;
    cancelOfferRequest(request);
    /* const requests = {
      post_id: postId,
    };
    dispatch(getPostDataOffer(requests, returnDataOffer));*/
    window.location.reload();
  };
  //console.log("BUYING_DATA", GET_BUYING_DATA);
  const acceptOfferBuyer = (e, data) => {
    var request = {};
    request.room_id = data.room;
    request.user_id = userInfo._id;
    request.language = selectedLang;
    request.paymentId = "";
    request.acceptBy = "SELLER";
    request.paymentType = data.paymentMethod;
    request.cardId = "";
    //console.log("request", request);
    acceptOfferRequest(request);
    setTimeout(function () {
      window.location.reload();
    }, 1000);
  };

  const handleCloseToogleSold = () => {
    setShowMarkAsSoldModal(false);
  };
  return (
    <>
      <section
        className="middle_banner_sec"
        style={{
          backgroundImage: `url(${GET_USER_PROFILE?.backgroundImage})`,
        }}
      ></section>

      <section className="manage_address">
        <Container>
          <div className="sidebar_outer">
            <div className="sidebar_main">
              <Sidebar />
            </div>

            <div className="dashboard_main outline_wrapper p-0">
              <div className="order_tracking_info_list mb-2 ml-9">
                <div className="tracking_head m-0">
                  <h2>
                    {" "}
                    <span className="d-inline-block">
                      <i className="far fa-clipboard"></i>
                    </span>{" "}
                    {t("MANAGE")}
                  </h2>
                </div>
              </div>
              <Row>
                <Col md={10}></Col>
                <Col md={2}>
                  <div className="add_new_btn">
                    <Button
                      className="theme_btn mt-1"
                      onClick={() => history.push("/create-post")}
                    >
                      {t("ADD_NEW_POST")}
                    </Button>
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  padding: "24px 30px 0 30px",
                  height: "1397px",
                  overflow: "auto",
                }}
              >
                <CustomTabs
                  tabArr={customTab}
                  defaultActiveKey={activeDefaultTab}
                  onSelect={onSelectTab}
                  content={
                    <>
                      {activeDefaultTab === "buying" ? (
                        <div className="trans_history_money sale_history_sec">
                          {LOADING ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <LottiLoader
                                loader={true}
                                height={150}
                                width={150}
                              />
                            </div>
                          ) : (
                            <>
                              {!LOADING && GET_BUYING_DATA.length === 0 && (
                                <Row>
                                  <LottiLoader
                                    message="No Post Found"
                                    height={250}
                                    width={250}
                                  />
                                </Row>
                              )}
                              <ul className="money_add">
                                {GET_BUYING_DATA &&
                                  GET_BUYING_DATA?.length !== 0 &&
                                  GET_BUYING_DATA?.map((item, key) => {
                                    return (
                                      <li key={key}>
                                        <div className="manage_post_ul">
                                          <div
                                            className="trans_date"
                                            // style={{ cursor: "pointer" }}
                                            // onClick={() => {
                                            //   if (!item.sold) {
                                            //     history.push(
                                            //       "/product/" + item?._id
                                            //     );
                                            //   }
                                            // }}
                                          >
                                            <div className="trans_date_list shadow-none border-0">
                                              <figure className="border-0">
                                                <img
                                                  src={item?.posts?.image}
                                                  alt=""
                                                />
                                                <small>
                                                  <i className="fas fa-heart favourite"></i>
                                                </small>
                                              </figure>
                                            </div>

                                            <div className="trans_title">
                                              <h3>{item?.posts?.title}</h3>
                                              <p className="m-0">
                                                {item?.offer_message}
                                              </p>
                                              <span>
                                                <i className="far fa-eye"></i>{" "}
                                                {item?.posts?.view} {t("VIEW")}
                                              </span>
                                            </div>
                                          </div>

                                          <div className="likes_btn">
                                            {item.offer_by === "SELLER" && (
                                              <Link
                                                to="#/"
                                                onClick={(e) =>
                                                  handleCounterView(e, item)
                                                }
                                                className="theme_btn bg-transparent text-dark border-dark btn btn-primary"
                                              >
                                                {t("COUNTER")}
                                              </Link>
                                            )}

                                            <Link
                                              to="#"
                                              /*onClick={() =>
                                                handleChatView(item?.room)
                                              }*/
                                              onClick={(e) =>
                                                handleChatClick(e, item.room)
                                              }
                                              className="onroute theme_btn"
                                            >
                                              {t("CHAT")}
                                            </Link>

                                            {item.offer_by === "SELLER" && (
                                              <Link
                                                to="#/"
                                                className="onroute theme_btn bg-success"
                                                onClick={(e) =>
                                                  acceptOfferBuyer(e, item)
                                                }
                                              >
                                                {t("ACCEPT")}
                                              </Link>
                                            )}
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>{" "}
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="trans_history_money sale_history_sec manage_post_sec">
                          {LOADING ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <LottiLoader
                                loader={true}
                                height={150}
                                width={150}
                              />
                            </div>
                          ) : (
                            <>
                              {!LOADING && GET_POST_DATA.length === 0 && (
                                <Row>
                                  {/* <div className="order_detail_box"> */}
                                  <LottiLoader
                                    message={t("NO_POST_FOUND")}
                                    height={250}
                                    width={250}
                                  />
                                  {/* </div> */}
                                </Row>
                              )}
                              <ul className="money_add">
                                {GET_POST_DATA &&
                                  GET_POST_DATA.length !== 0 &&
                                  GET_POST_DATA.map((item, key) => {
                                    return (
                                      <li key={key}>
                                        {!item?.sold && (
                                          <div className="boost_duration">
                                            <div className="non-semantic-protector">
                                              {item?.boost && (
                                                <h5 className="ribbon">
                                                  <div
                                                    className="ribbon-content "
                                                    style={{
                                                      fontWeight: "100",
                                                      fontSize: "14px",
                                                    }}
                                                  >
                                                    {t("BOOST_DURATION")} :{" "}
                                                    {item?.day !== 0 ? (
                                                      <span>
                                                        {item?.day}{" "}
                                                        {t("DAYS_LEFT")}
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        {item?.hours}{" "}
                                                        {t("HOUR")}
                                                      </span>
                                                    )}
                                                  </div>
                                                </h5>
                                              )}
                                            </div>

                                            <Dropdown>
                                              <Dropdown.Toggle
                                                variant="success"
                                                id="dropdown-basic"
                                                className="nav_dots"
                                              >
                                                <img
                                                  src="./assets/images/nav_dots.png"
                                                  alt=""
                                                  style={{ width: "4px" }}
                                                />{" "}
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  href="#"
                                                  onClick={() =>
                                                    handelArchivePost(item?._id)
                                                  }
                                                >
                                                  {t("ARCHIVE")}
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        )}

                                        <div className="manage_post_ul">
                                          <div
                                            className="trans_date"
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) =>
                                              handleOfferList(
                                                e,
                                                item,
                                                item.view
                                              )
                                            }
                                            //onClick={() => {
                                            //   if (!item.sold) {
                                            //     history.push(
                                            //        "/product/" + item?._id
                                            //      );
                                            //    }
                                            //   }}
                                          >
                                            <div className="trans_date_list shadow-none border-0">
                                              <figure
                                                className="border-0"
                                                style={{ position: "relative" }}
                                              >
                                                <img
                                                  src={item?.image}
                                                  alt="No_Img"
                                                  className="manage-post-img"
                                                />
                                                {item?.sold && (
                                                  <p
                                                    style={{
                                                      backgroundColor:
                                                        "rgb(210 163 32 / 60%)",
                                                      textAlign: "center",
                                                      color: "#ffffff",
                                                      fontWeight: "bold",
                                                      width: "54px",
                                                      borderRadius: "8px",
                                                      position: "absolute",
                                                      top: "5px",
                                                      left: "8px",
                                                      border:
                                                        "1px solid rgb(210 163 32 / 60%)",
                                                    }}
                                                  >
                                                    {t("SOLD")}
                                                  </p>
                                                )}
                                              </figure>
                                            </div>

                                            <div className="trans_title">
                                              <h3>
                                                {textTruncate(item?.title)}{" "}
                                              </h3>
                                              {item?.sold ? (
                                                <p className="m-0 danger fw-bold fs-6">
                                                  {t("SOLD")}
                                                </p>
                                              ) : (
                                                <p className="m-0">
                                                  {item?.offer}
                                                </p>
                                              )}
                                              <span>
                                                <i className="far fa-eye"></i>{" "}
                                                {item?.view} {t("VIEW")}
                                              </span>
                                            </div>
                                          </div>
                                          {!item?.sold && (
                                            <div className="likes_btn">
                                              {!item?.boost && (
                                                <Button
                                                  className="onroute theme_btn"
                                                  // onClick={handleShow2(item._id)}
                                                  onClick={(e) =>
                                                    handleShow2(item._id)
                                                  }
                                                >
                                                  {t("BOOST_BTN")}
                                                </Button>
                                              )}
                                              {!item?.sold && (
                                                <Button
                                                  className="onroute theme_btn"
                                                  onClick={() =>
                                                    toggleMarkAsSoldModal(item)
                                                  }
                                                >
                                                  {t("MARK_AS_SOLD")}
                                                </Button>
                                              )}
                                              {!item?.offerAccept && (
                                                <Button
                                                  className="theme_btn btn btn-primary"
                                                  onClick={() =>
                                                    fetchEditPostData(
                                                      item?._id,
                                                      "manage"
                                                    )
                                                  }
                                                >
                                                  {/*  <i className="fas fa-pen"></i> */}
                                                  {t("EDIT")}
                                                </Button>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </>
                          )}
                        </div>
                      )}
                    </>
                  }
                />
              </div>
              {!LOADING && GET_POST_DATA.length !== 0 && (
                <>
                  <Divider />
                  <div
                    className="d-flex justify-content-between "
                    style={{ padding: "20px 24px 13px" }}
                  >
                    <p>
                      <strong>{t("TOTAL_ITEM")}</strong> :{" "}
                      <span>{POST_LIST_PAGINATION?.total}</span>
                    </p>
                    <Pagination
                      page={page}
                      count={POST_LIST_PAGINATION?.total_pages}
                      color="primary"
                      variant="outlined"
                      onChange={(e, value) => pagination(e, value)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Container>
        {/* Subscription Start */}

        <Dialog
          open={show2}
          onClose={handleClose2}
          className="subscribe_modal"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="with-crose">
            {" "}
            <div class="text-center modal-title h4">
              <span>{t("SUBSCRIPTION_TITLE")}</span>

              {t("SUBSCRIPTION_DESCRIPTION")}
              <button className="crose_btn" onClick={handleClose2}>
                <img src={crose} alt="img" />
              </button>
            </div>
          </DialogTitle>
          <DialogContent className="pt-2 px-0">
            <Row className="align-items-center mb-3">
              {getSubscriptionData &&
                getSubscriptionData.item &&
                getSubscriptionData.item.map((item, key) => (
                  <Col
                    key={key}
                    onClick={() => handleActiveState(item._id, item.amount)}
                  >
                    <div
                      className={`outer shadow-sm rounded ${
                        getActiveState === item._id ? "active" : ""
                      }`}
                    >
                      <h4>
                        <span>{getNumber(item.translate.title)}</span>Days
                      </h4>
                      <h3>
                        {TextFormate(item.amount, 2)} {t("SR")}
                      </h3>
                    </div>
                  </Col>
                ))}
            </Row>
            <div className="text-center mb-3 mb-md-4">
              <a
                href="#/"
                onClick={remainingBoost}
                className="text-large link_color"
              >
                {t("REMAINING_BOOST")}
              </a>
            </div>
            <div className="bg-light p-3 mb-3">
              <ul className="p-0 m-0">
                {getSubscriptionData &&
                  getSubscriptionData.item &&
                  getSubscriptionData.item.map(
                    (item, key) =>
                      getActiveState === item._id && (
                        <li key={key} className="boost-description-cs">
                          <img
                            className="me-1"
                            src={checkmark_bullet}
                            alt="bullet"
                          />{" "}
                          {parse(item.translate.description)}
                        </li>
                      )
                  )}
                {/*<li>
                  <img className="me-1" src={checkmark_bullet} alt="bullet" />{" "}
                  Lorem ipsum dolor sit amet, consectetuer adipiscing.
                    </li> */}
              </ul>
            </div>
            <a href="#/" onClick={handleShow4} className="theme-color">
              {t("HOW_IT_WORKS")}{" "}
              <img className="me-1" src={right_arrow} alt="right_arrow" />
            </a>

            <div className="d-flex align-items-center mt-3 mt-md-4 subscription-mod">
              <a
                href="#/"
                onClick={handleBuySubscription}
                className="theme_btn btn rounded text-white  p-2 p-md-3 h-auto"
              >
                {t("APPLY_BOOST")}
              </a>
              <a
                href="#/"
                className="trans_btn btn rounded text-white  ms-2 p-2 p-md-3 h-auto"
              >
                View Add
              </a>
            </div>
          </DialogContent>
        </Dialog>

        {/* Subscription End */}
        {/* How this Works Start */}

        <Dialog
          open={show4}
          onClose={handleClose4}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="with-crose">
            {" "}
            {t("HOW_BOOST_WORKS")}
            <button className="crose_btn" onClick={handleClose4}>
              <img src={crose} alt="crose" />
            </button>
          </DialogTitle>

          <DialogContent className="pt-2">
            <ul className="work_list p-0 m-0">
              <li>
                <strong>{t("INCREASE_NUMBER_VIEWS")}</strong>
                <br />
                {t("FIRST_TEXT")}
              </li>
              <li>
                <strong>{t("BE_IN_TOP")}</strong>
                <br />
                {t("SECOND_TEXT")}
              </li>
              <li>
                <strong>{t("SPOTS_ARE_SHARED")}</strong>
                <br />
                {t("THIRD_TEXT")}
              </li>
            </ul>
          </DialogContent>
        </Dialog>

        {/* How this Works End */}
        {/* Remaining Boost */}
        <Dialog
          open={showModelR}
          onClose={handleCloseR}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="remaining-dialog"
        >
          <DialogTitle className="with-crose">
            {" "}
            {t("SELECT_REMAINING_BOOST")}
            <button className="crose_btn" onClick={handleCloseR}>
              <img src={crose} alt="crose" />
            </button>
          </DialogTitle>

          <DialogContent className="pt-2">
            {remainingBoostData.lenght > 0 ? (
              <ul className="remaining-boost work_list p-0 m-0">
                {remainingBoostData &&
                  remainingBoostData.map((item, key) => (
                    <li
                      key={key}
                      className={`${
                        getRemainingActiveState === item._id ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        className="radio-remaining"
                        onClick={() => handleActiveRemaining(item)}
                        value={item.title}
                        name="remaining_boost"
                      />
                      <span className="title-r-boost">
                        <strong>{item.title}</strong>
                      </span>
                      <span className="description-r-boost">
                        {" "}
                        <strong>{item.message}</strong>
                      </span>
                    </li>
                  ))}
              </ul>
            ) : (
              <h3 className="boost-h3s">
                {t("CURRENTLY_BOOST_NOT_AVAILABLE")}
              </h3>
            )}

            <div className="d-flex align-items-center mt-1 mt-md-2 subscription-mod">
              <a
                href="#/"
                onClick={handleRemainingBoostApply}
                className="theme_btn btn rounded text-white  p-2 p-md-3 h-auto"
              >
                {t("APPLY_BOOST")}
              </a>
            </div>
          </DialogContent>
        </Dialog>

        {/* Remaining Boost */}
        {/* Card */}
        <Dialog
          open={showModelCard}
          onClose={handleCloseCard}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="with-crose">
            {" "}
            {t("SELECT_CARD")}
            <button className="crose_btn" onClick={handleCloseCard}>
              <img src={crose} alt="img" />
            </button>
          </DialogTitle>

          <DialogContent className="pt-2">
            {getCardList.card && getCardList.card.length > 0 ? (
              <ul className="remaining-boost work_list p-0 m-0">
                {getCardList.card &&
                  getCardList.card.map((item, key) => (
                    <li
                      key={key}
                      className={`${
                        getCardActiveState === item._id ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        className="radio-remaining"
                        onClick={() => handleActiveCard(item)}
                        value={item.title}
                        name="remaining_boost"
                      />
                      <span className="title-r-boost">
                        <img src={item.cardBrand} alt={key} />{" "}
                        <strong>{item.maskedCardNo}</strong>
                      </span>
                    </li>
                  ))}
              </ul>
            ) : (
              <h3 className="boost-h3s">{t("CURRENTLY_CARD_NOT_AVAILABLE")}</h3>
            )}

            <div className="d-flex align-items-center mt-3 mt-md-4 subscription-mod">
              <a
                href="#/"
                onClick={handleCardBoostApply}
                className="theme_btn btn rounded text-white  p-2 p-md-3 h-auto"
              >
                {t("APPLY_BOOST")}
              </a>
              <Button
                type="submit"
                disabled={double}
                className="text-white  ms-2 p-2 p-md-3 h-auto card-add-cs text-white"
                onClick={() => formik.handleSubmit()}
              >
                {t("ADD_CARD")}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        {/* Card */}
        {show && (
          <CounterOffer
            userId={userInfo._id}
            roomId={getRoomId}
            item={getCounterData}
            open={show}
            setOpen={setShow}
            postId={getCounterData && getCounterData.posts._id}
            roomDetails=""
            authorizeType="Seller_Counter"
          />
        )}
      </section>

      {showMarkAsSoldModal && (
        <MarkAsSoldModal
          show={showMarkAsSoldModal}
          onHide={() => toggleMarkAsSoldModal()}
          handleCloseToogleSold={handleCloseToogleSold}
          markAsSoldData={markAsSoldData}
        />
      )}
      {/* Offer List Model*/}
      <Dialog
        onClose={handleCloseOffer}
        open={showOfferList}
        fullWidth={true}
        maxWidth="md"
        className="offer-list-cs"
      >
        {/* <DialogTitle>Set backup account</DialogTitle>*/}

        <div className="outline_wrapper border-0">
          <div className="order_tracking_info_list ">
            <div className="tracking_head">
              <h2> {getDataItem && getDataItem.postData.title}</h2>
            </div>
          </div>

          <div className="buyer-service offer-top-hd px-0 mb-2 mb-md-3">
            <figure className="buyer-thumb-outer">
              <span className="img-thumb">
                <img src={getDataItem && getDataItem.postData.image} alt="" />
              </span>
              <figcaption>
                <h5>
                  <a href="#/">
                    {getDataItem && TextFormate(getDataItem.postData.price, 2)}{" "}
                    {t("SR")}
                  </a>
                </h5>
                <small className="view-count">
                  <i className="far fa-eye"></i> {getViewItem} {t("VIEWS")}
                </small>
                {getDataItem &&
                  getDataItem.postData &&
                  !getDataItem.postData?.sold && (
                    <span>
                      {getDataItem.postData?.boost && (
                        <span className="red_text">
                          <span>
                            {t("BOOST_DURATION")} :{" "}
                            {getDataItem.postData?.day !== 0 ? (
                              <span>
                                {getDataItem.postData?.day} {t("DAYS_LEFT")}
                              </span>
                            ) : (
                              <span>
                                {getDataItem.postData?.hours} {t("HOUR")}
                              </span>
                            )}
                          </span>
                        </span>
                      )}
                    </span>
                  )}
              </figcaption>
            </figure>

            <div className="offer-right_btn_service offer-list-service-cs">
              <a href="#/" className="">
                <img src={themeboost} alt="1" />
              </a>
              {getDataItem &&
                getDataItem.postData &&
                !getDataItem.postData.boost && (
                  <Button
                    className="onroute theme_btn"
                    // onClick={handleShow2(item._id)}
                    onClick={(e) => handleShow2(getDataItem.postData._id)}
                  >
                    {t("BOOST_BTN")}
                  </Button>
                )}{" "}
              {getDataItem &&
                getDataItem.postData &&
                !getDataItem.postData.sold && (
                  <Button
                    className="onroute theme_btn"
                    onClick={() => toggleMarkAsSoldModal(getDataItem.postData)}
                  >
                    {t("MARK_AS_SOLD")}
                  </Button>
                )}
              {getDataItem &&
                getDataItem.postData &&
                !getDataItem.postData?.offerAccept && (
                  <Button
                    className="theme_btn btn btn-primary"
                    onClick={() =>
                      fetchEditPostData(getDataItem.postData?._id, "manage")
                    }
                  >
                    {/*  <i className="fas fa-pen"></i> */}
                    {t("EDIT")}
                  </Button>
                )}
            </div>
          </div>
          <h5>{t("OFFERS")}</h5>
          <div className="trans_history_money sale_history_sec manage_post_sec offer-page-outline">
            <ul className="money_add">
              {getDataItem &&
                getDataItem.data.map((item, key) => (
                  <li key={key}>
                    <div className="boost_duration">
                      <span className="timing-vl"></span>
                      <a href="#/" className="">
                        <img src={themeboost} alt="1" />
                      </a>
                    </div>

                    <div className="manage_post_ul">
                      <div className="trans_date">
                        <div className="trans_date_list shadow-none border-0">
                          <figure className="border-0">
                            <img src={item.user.first_name} alt="" />
                            <small>
                              <i className="fas fa-heart favourite"></i>
                            </small>
                          </figure>
                        </div>

                        <div className="trans_title">
                          <h3>{item.user.first_name}</h3>
                          <span className="price">
                            {TextFormate(item.price, 2)} {t("SR")}
                          </span>
                          <div className="paymethod applypay">
                            <small>
                              {t("PAYMENT_METHOD")}
                              <span className="ms-2 me-0">
                                {item.paymentMethod === "ONLINE" ? (
                                  <span>
                                    {" "}
                                    <img src={card} alt="1" />{" "}
                                    {item.paymentMethod}
                                  </span>
                                ) : (
                                  <span>
                                    <img src={Cash} alt="1" />
                                    {item.paymentMethod}
                                  </span>
                                )}
                              </span>
                            </small>
                          </div>
                        </div>
                      </div>
                      {item.offer_by === "USER" && (
                        <div className="likes_btn">
                          <Button
                            className="onroute theme_btn"
                            onClick={(e) =>
                              counterOfferSeller(
                                e,
                                item,
                                getDataItem.postData._id,
                                "counter"
                              )
                            }
                          >
                            {t("COUNTER")}
                          </Button>
                          <Button
                            className="onroute theme_btn"
                            onClick={(e) => handleChatClick(e, item.room)}
                          >
                            {t("CHAT")}
                          </Button>
                          <Button
                            className="onroute theme_btn green_bg"
                            onClick={(e) =>
                              handleAcceptOffer(
                                e,
                                item,
                                getDataItem.postData._id
                              )
                            }
                          >
                            {t("ACCEPT")}
                          </Button>
                        </div>
                      )}
                      {item.offer_by === "SELLER" && (
                        <div className="likes_btn">
                          {item.status === "CANCELED" && (
                            <Button
                              className="onroute theme_btn"
                              onClick={(e) =>
                                counterOfferSeller(
                                  e,
                                  item,
                                  getDataItem.postData._id,
                                  "offer"
                                )
                              }
                            >
                              {t("OFFER")}
                            </Button>
                          )}

                          <Button
                            className="onroute theme_btn"
                            onClick={(e) => handleChatClick(e, item.room)}
                          >
                            {t("CHAT")}
                          </Button>
                          {item.status !== "CANCELED" && (
                            <Button
                              className="onroute theme_btn"
                              onClick={(e) =>
                                handleCancelOffer(
                                  e,
                                  item,
                                  getDataItem.postData._id
                                )
                              }
                            >
                              {t("OFFER_CANCEL")}
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </Dialog>
      {/* Offer List Model*/}

      {showCounter && (
        <CounterOfferSeller
          userId={userInfo._id}
          roomId={getCounterDataOffer.room}
          item={getCounterDataOffer}
          postId={getCounterDataPost}
          post={getCounterDataPost}
          open={showCounter}
          setOpen={setShowCounter}
          offerType={getOfferType}
        />
      )}
    </>
  );
}

export default Manage;
