import { Select } from "@mui/material";
import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Dropdown,
  Modal,
  Form,
  Button,
} from "react-bootstrap";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Sidebar from "../Sidebar";
import { useTranslation } from "react-i18next";
import CryptoJS from "crypto-js";
import axios from "axios";
import {
  fetchBankData,
  fetchCardData,
  saveUpdateBankData,
  addCard,
} from "./../../../actions/bankAction";
import { ErrorMessage } from "@hookform/error-message";
import config from "./../../../Component/Urway/index.json";
import moment from "moment";
const queryString = require("query-string");

const emailRegexp = new RegExp(
  "^[a-zA-Z0-9]+([a-zA-Z0-9_.\\$&-]+)*@[a-zA-Z0-9]+([a-zA-Z0-9_.\\$&-]+)*\\.([a-zA-Z0-9]{2,4})$"
);
const ipRegexp = new RegExp(
  "^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
);
const amountRegexp = new RegExp("^[0-9]?((.[0-9]+)|([0-9]+(.[0-9]+)?))$");

const generateHashSHA256 = (hashSequence) => {
  // hashSequence = trackid | terminalId | password | secret | amount | currency
  let hash = CryptoJS.SHA256(hashSequence).toString();
  return hash;
};
let resParameter = {};
const { REACT_APP_PUBLIC_URL } = process.env;

function PaymentMethod(props) {
  const { push } = useHistory();

  const queryStringParsed = queryString.parse(props.location.search);

  const [show3, setShow3] = useState(false);

  const [showAddBank, setShowAddBank] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleHideAddBank = () => setShowAddBank(false);
  const handleShowAddBank = () => setShowAddBank(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  const { t } = useTranslation();

  const { bankData, userInfo } = useSelector((state) => ({
    bankData: state.bankData,
    cardData: state.cardData,
    userInfo: state.userInfo,
  }));

  const dispatch = useDispatch();

  const [bankId, setBankId] = useState("");
  const [ibanNumber, setIbanNumber] = useState("");

  useEffect(() => {
    const fetchData = () => {
      dispatch(fetchBankData());
      dispatch(fetchCardData());
    };
    fetchData();
  }, [dispatch]);

  if (Object.keys(bankData.cardData).length > 0) {
    if (bankId === "" && bankData.cardData.bank._id !== undefined) {
      setBankId(bankData.cardData.bank._id);
    }
    if (ibanNumber === "" && bankData.cardData.bank.iban_number !== undefined) {
      setIbanNumber(bankData.cardData.bank.iban_number);
    }
  }

  // All Selectors
  const { GET_USER_PROFILE } = useSelector((state) => ({
    GET_USER_PROFILE: state?.sidebarReducer?.userProfileData,
  }));

  const handleSubmitBankDetail = () => {
    try {
      dispatch(
        saveUpdateBankData({
          bank_id: bankId,
          iban_number: ibanNumber,
        })
      );
      dispatch(fetchCardData());
      dispatch(fetchBankData());
      setTimeout(() => {
        handleHideAddBank();
      }, 1000);
    } catch (error) {
      console.log("error", error);
    }
  };

  const [double, setDouble] = useState(false);
  let index = 0;
  let count = 0;
  let queryParam = "";
  let trackId = moment().unix();
  const [tokenOperation, setTokenOperation] = useState("A");
  const [cardToken, setCardToken] = useState("");
  const [cardId, setCardId] = useState(null);
  const initialValues = {
    firstName: userInfo.first_name,
    lastName: userInfo.last_name,
    address: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: userInfo.mobile_number,
    trackid: trackId.toString(),
    terminalId: config.terminalId,
    customerEmail: userInfo.email,
    action: "12",
    merchantIp: "111.93.58.10",
    password: config.password,
    currency: "SAR",
    country: "SA",
    transid: "",
    amount: "1",
    tokenOperation: tokenOperation,
    cardToken: "",
    tokenizationType: "1",
    requestHash: "",
    udf1: "",
    udf2: REACT_APP_PUBLIC_URL,
    udf3: "",
    udf4: "",
    udf5: "",
  };


  const validate = (values) => {
    let errors = {};
    // console.log("values : ", values);
    if (!values.country) {
      errors.errorCountry = "Country Code Is Required";
      // isDisabel=false;
    } else if ((values.country + "").length !== 2) {
      errors.errorCountry = "Enter Valid Country Code";
      // isDisabel=false;
    }
    if (!emailRegexp.test(values.customerEmail)) {
      errors.errorEmail = "Invalid email";
      // isDisabel=false;
    }
    if (!ipRegexp.test(values.merchantIp)) {
      errors.errorMerchantIp = "Invalid Merchant IP";
      // isDisabel=false;
    }
    if (!amountRegexp.test(values.amount)) {
      errors.errorAmount = "Invalid Amount";
      // isDisabel=false;
    }
    if (!values.trackid) {
      errors.errorTrackid = "Track ID Is Required";
      // isDisabel=false;
    }
    if (!values.currency) {
      errors.errorCurrency = "Currency Is Required";
      // isDisabel=false;
    } else if (values.currency.length < 3) {
      errors.errorCurrency = "Enter Valid Currency";
      // isDisabel=false;
    }
    if (!values.action) {
      errors.errorAction = "Please Select Action";
      // isDisabel=false;
    }
    if (values.action + "" === "12")
      if (values.tokenOperation + "" === "") {
        errors.errorTokenOperation = "Please Select Token Operation";
        // isDisabel=false;
      }
    if (
      (values.action + "" === "2") |
      (values.action + "" === "5") |
      (values.action + "" === "3") |
      (values.action + "" === "9") |
      (values.action + "" === "10") |
      (values.action + "" === "6")
    ) {
      if (!values.transid) {
        errors.errorTransid = "Transaction ID Is Required";
        // isDisabel=false;
      }
    }
    // if(!(onlyDigitRegexp.test(values.zipCode)))
    //     errors.errorZipCode="Enter Valid Postal Code"
    return errors;
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      // console.log("double", double);
      setDouble(true);
      values.country = (values.country + "").toUpperCase();
      values.currency = (values.currency + "").toUpperCase();
      localStorage.setItem("getCurrency", (values.currency + "").toUpperCase());
      localStorage.setItem("isDirectReceipt", "false");
      let hashSequence = generateHashSHA256(
        values.trackid +
          "|" +
          values.terminalId +
          "|" +
          config.password +
          "|" +
          config.merchantKey +
          "|" +
          values.amount +
          "|" +
          values.currency
      );

      values.requestHash = hashSequence;
      if (cardToken !== "" && tokenOperation === "D" && cardId !== null) {
        values.cardToken = cardToken;
        values.tokenOperation = tokenOperation;
        values.udf2 = `${REACT_APP_PUBLIC_URL}?card_id=${cardId}`;
      }
      console.log('onSubmit', values)
      axios
        .post(config.service_url, values)
        .then((res) => {
          resParameter = res.data;
          if (resParameter.targetUrl + "" === "null") {
            for (let [key, value] of Object.entries(resParameter)) {
              index = ++index;
              console.log(`${key} ${value}`);
            }
            for (let [key, value] of Object.entries(resParameter)) {
              count = ++count;
              queryParam = queryParam + key + "=" + value;
              if (count < index) queryParam = queryParam + "&";
            }
            window.location.assign(
              window.location.origin.toString() + "/paymentStatus?" + queryParam
            );
            setDouble(true);
          } else {
            window.location.assign(
              res.data.targetUrl.replace("?", "") +
                "?paymentid=" +
                res.data.payid
            );
            setDouble(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validate,
  });

  return (
    <>
      <section
        className="middle_banner_sec"
        style={{
          backgroundImage: `url(${GET_USER_PROFILE?.backgroundImage})`,
        }}
      ></section>

      <section className="manage_address">
        <Container>
          <div className="sidebar_outer">
            <div className="sidebar_main">
              <Sidebar />
            </div>

            <div className="dashboard_main outline_wrapper pb-5">
              <h2>Choose Payment Method</h2>
              <div className="payment_horizantal">
                <Row>
                  <Col lg={4} md={6}>
                    <div className="pay-advt">
                      {/* <label className="checkbox_tab">
                        <input type="checkbox" />
                        <span className="checkbox_design"></span>
                      </label> */}
                      <span>
                        <img src="./assets/images/gpay.png" />
                      </span>
                      <strong>STC Pay</strong>
                      {/* <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="nav_dots"
                        >
                          <img src="./assets/images/nav_dots.png" />{" "}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            Set as Default
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </div>
                  </Col>
                </Row>

                <div className="creadit_cards">
                  <h2>Payout Method</h2>
                  <Row>
                    {Object.keys(bankData.cardData).length > 0 &&
                      Object.keys(bankData.cardData.bank).length > 0 && (
                        <Col lg={4} md={6}>
                          <div className="pay-advt white_bg">
                            {/* <label className="checkbox_tab">
                              <input type="checkbox" />
                              <span className="checkbox_design"></span>
                            </label> */}
                            <span>
                              <img width={25} src="./assets/images/bank.png" />
                            </span>
                            <strong>
                              {bankData.cardData.bank.title}{" "}
                              <small>{bankData.cardData.bank.code}</small>
                            </strong>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                                className="nav_dots"
                              >
                                <img src="./assets/images/nav_dots.png" />{" "}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  href="javascript:"
                                  onClick={() => handleShowAddBank()}
                                >
                                  Edit
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Col>
                      )}
                    {Object.keys(bankData.cardData).length > 0 &&
                      Object.keys(bankData.cardData.bank).length === 0 && (
                        <Col lg={4} md={6}>
                          <div className="pay-advt">
                            <button
                              className="add_bank-detail"
                              onClick={() => handleShowAddBank()}
                            >
                              + Add Bank Detail
                            </button>
                          </div>
                        </Col>
                      )}
                  </Row>
                </div>

                <div className="creadit_cards">
                  <h2>MADA or Credit Card</h2>
                  <Row>
                    {Object.keys(bankData.cardData).length > 0 &&
                      bankData.cardData.card.length > 0 &&
                      bankData.cardData.card.map((cardObj) => (
                        <Col lg={4} md={6}>
                          <div className="pay-advt white_bg">
                            {/* <label className="checkbox_tab">
                              <input type="checkbox" />
                              <span className="checkbox_design"></span>
                            </label> */}
                            <span>
                              <img width={40} src={cardObj.cardBrand} />
                            </span>
                            <strong>
                              <small>{cardObj.maskedCardNo}</small>
                            </strong>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                                className="nav_dots"
                              >
                                <img src="./assets/images/nav_dots.png" />{" "}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {/* <Dropdown.Item href="#/action-1">
                                  Set as Default
                                </Dropdown.Item> */}
                                <Dropdown.Item
                                  href="#"
                                  onClick={() => {
                                    setTokenOperation("D");
                                    setCardToken(cardObj.cardToken);
                                    setCardId(cardObj._id);
                                    formik.handleSubmit();
                                  }}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Col>
                      ))}
                  </Row>

                  <Button
                    type="submit"
                    disabled={double}
                    className="btn btn-primary theme_btn  add-new-card"
                    onClick={() => formik.handleSubmit()}
                  >
                    Add New Card
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <Modal
        size="sm"
        show={showAddBank}
        onHide={handleHideAddBank}
        className="add_card"
      >
        <button className="crose_btn" onClick={handleHideAddBank}>
          <img src="./assets/images/crose.png" />
        </button>
        <Modal.Body>
          <div className="add_new_card">
            <h4>
              {Object.keys(bankData.cardData).length > 0 ? "Edit" : "Add"} Your
              Bank Details
            </h4>
            <Form className="addcard_form">
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="formGroupBank">
                    <select
                      className="form-control"
                      value={bankId}
                      name="bankId"
                      onChange={(e) => setBankId(e.target.value)}
                    >
                      {undefined !== bankData.bankData &&
                        bankData.bankData.length > 0 &&
                        bankData.bankData.map((bank) => (
                          <option value={bank._id}>{bank.title}</option>
                        ))}
                    </select>
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="mb-3" controlId="formGroupIBAN">
                    <Form.Control
                      type="text"
                      name="ibanNumber"
                      value={ibanNumber}
                      placeholder="IBAN Number"
                      maxLength={24}
                      onChange={(e) => setIbanNumber(e.target.value)}
                      autoFocus={true}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="ibanNumber"
                      render={({ message }) => (
                        <p className="error-message">{message}</p>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button
                className="theme_btn"
                onClick={() => handleSubmitBankDetail()}
              >
                Add
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      {/* <section className="make_offer_section">
        <Container>
          <div className="breadcrumb">
            <ul>
              <li>
                <a href="#">
                  Home<i class="fas fa-chevron-right"></i>
                </a>
              </li>
              <li>
                <span>Payment & Payout</span>
              </li>
            </ul>
          </div>

          <div className="outline_wrapper pb-5">
            <h2>Choose Payment Method</h2>
            <div className="payment_horizantal">
              <Row>
                <Col lg={3} md={6}>
                  <div className="pay-advt">
                    <label className="checkbox_tab">
                      <input type="checkbox" />
                      <span className="checkbox_design"></span>
                    </label>
                    <span>
                      <img src="./assets/images/gpay.png" />
                    </span>
                    <strong>Sts Pay</strong>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="nav_dots"
                      >
                        <img src="./assets/images/nav_dots.png" />{" "}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">
                          Set as Default
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>

                <Col lg={3} md={6}>
                  <div className="pay-advt">
                    <label className="checkbox_tab">
                      <input type="checkbox" />
                      <span className="checkbox_design"></span>
                    </label>
                    <span>
                      <img src="./assets/images/applepay.png" />
                    </span>
                    <strong>Apple Pay</strong>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="nav_dots"
                      >
                        <img src="./assets/images/nav_dots.png" />{" "}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">
                          Set as Default
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>

                <Col lg={3} md={6}>
                  <div className="pay-advt">
                    <label className="checkbox_tab">
                      <input type="checkbox" />
                      <span className="checkbox_design"></span>
                    </label>
                    <span>
                      <img src="./assets/images/method_cash.png" />
                    </span>
                    <strong>Cash</strong>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="nav_dots"
                      >
                        <img src="./assets/images/nav_dots.png" />{" "}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">
                          Set as Default
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>

                <Col lg={3} md={6}>
                  <div className="pay-advt">
                    <button className="add_bank-detail">
                      + Add Bank Detail
                    </button>
                  </div>
                </Col>
              </Row>

              <div className="creadit_cards">
                <h2>MADA or Credit Card</h2>
                <Row>
                  <Col lg={3} md={6}>
                    <div className="pay-advt white_bg">
                      <label className="checkbox_tab">
                        <input type="checkbox" />
                        <span className="checkbox_design"></span>
                      </label>
                      <span>
                        <img src="./assets/images/visa.png" />
                      </span>
                      <strong>
                        Ahmed Gaafar <small>XXXX-XXXX-XXXX-5432</small>
                      </strong>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="nav_dots"
                        >
                          <img src="./assets/images/nav_dots.png" />{" "}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            Set as Default
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>

                  <Col lg={3} md={6}>
                    <div className="pay-advt white_bg">
                      <label className="checkbox_tab">
                        <input type="checkbox" />
                        <span className="checkbox_design"></span>
                      </label>
                      <span>
                        <img src="./assets/images/visa.png" />
                      </span>
                      <strong>
                        Mohammed Ali<small>XXXX-XXXX-XXXX-5432</small>
                      </strong>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="nav_dots"
                        >
                          <img src="./assets/images/nav_dots.png" />{" "}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            Set as Default
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>

                  <Col lg={3} md={6}>
                    <div className="pay-advt white_bg">
                      <label className="checkbox_tab">
                        <input type="checkbox" />
                        <span className="checkbox_design"></span>
                      </label>
                      <span>
                        <img src="./assets/images/visa.png" />
                      </span>
                      <strong>
                        Baha' al-Din<small>XXXX-XXXX-XXXX-5432</small>
                      </strong>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="nav_dots"
                        >
                          <img src="./assets/images/nav_dots.png" />{" "}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            Set as Default
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>

                <button
                  className="btn btn-primary theme_btn  add-new-card"
                  onClick={handleShow3}
                >
                  Add New Card
                </button>
              </div>
            </div>
          </div>
        </Container>
      </section> */}

      {/* add_card */}

      <Modal show={show3} onHide={handleClose3} className="add_card">
        <button className="crose_btn" onClick={handleClose3}>
          <img src="./assets/images/crose.png" />
        </button>
        <Modal.Body>
          <div className="add_new_card">
            <h4>Add New Card</h4>
            <Form className="addcard_form">
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Control type="text" placeholder="Card Holder Name" />
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Control type="text" placeholder="Card Number" />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Control type="text" placeholder="Expiry MM/YY" />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Control type="text" placeholder="CVV" />
                  </Form.Group>
                </Col>
              </Row>

              <Button className="theme_btn">Add Card</Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PaymentMethod;
