import submittingRequestStatus from "./submittingRequestStatusAction";
import * as API from "../utils/apiPath";
import { agent } from "../utils/agent";
import { newAgent } from "../utils/newAgent";
import toasterStatusAction from "./toasterStatusAction";
import { loadToasterData } from "./baseActions";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";

const POST_LIST = (data) => {
  return {
    type: "POST_LIST",
    payload: data,
  };
};

const POST_LIST_PAGINATION = (data) => {
  return {
    type: "POST_LIST_PAGINATION",
    payload: data,
  };
};

const EDIT_POST_DATA = (data) => {
  return {
    type: "EDIT_POST_DATA",
    payload: data,
  };
};

const LOADING_STATUS = (data) => {
  return {
    type: "LOADING_STATUS",
    payload: data,
  };
};

const BUYING_LIST = (data) => {
  return {
    type: "BUYING_LIST",
    payload: data,
  };
};

const USER_SOLD_LIST = (data) => {
  return {
    type: "USER_SOLD_LIST",
    payload: data,
  };
};

const USER_SOLD_LIST_LOADER = (data) => {
  return {
    type: "USER_SOLD_LIST_LOADER",
    payload: data,
  };
};

export {
  POST_LIST,
  EDIT_POST_DATA,
  POST_LIST_PAGINATION,
  LOADING_STATUS,
  BUYING_LIST,
  USER_SOLD_LIST,
  USER_SOLD_LIST_LOADER,
};

// Api Functions

// get post
export const fetchPostList = (pageNo, Keyword) => async (dispatch) => {
  try {
    dispatch(LOADING_STATUS(true));
    const response = await agent.get(
      `${API.GET_POST}?page=${pageNo ? pageNo : 1}&keyword=${
        Keyword ? Keyword : ""
      }`
    );
    // console.log("Response of Post List", response.data.data);
    if (response.status === 200) {
      dispatch(POST_LIST(response?.data?.data?.data));
      dispatch(BUYING_LIST([]));
      dispatch(POST_LIST_PAGINATION(response?.data?.data?.pagination));
      dispatch(LOADING_STATUS(false));
    }
  } catch (error) {
    dispatch(LOADING_STATUS(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const getEditPost = (id, history, text) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await newAgent.get(`${API.NEW_GET_EDIT_POST}/${id}`);
    if (response.status === 200) {
      dispatch(EDIT_POST_DATA(response?.data?.data));
      dispatch(toggleNetworkRequestStatus(false));
      history.push({
        pathname: "/edit-post/" + id,
        state: { data: response?.data?.data, type: text ? text : "" },
      });
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};
export const getEditPostSell = (id, history, text) => async (dispatch) => {
  console.log(id, history, text)
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await newAgent.get(`${API.NEW_GET_EDIT_POST}/${id}`);
    if (response.status === 200) {
      dispatch(EDIT_POST_DATA(response?.data?.data));
      dispatch(toggleNetworkRequestStatus(false));
      history.push({
        pathname: "/sell-another/" + id,
        state: { data: response?.data?.data, type: text ? text : "" },
      });
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

//Archive Post
export const archivePost = (data) => async (dispatch) => {
  console.log(data)
  try {
    dispatch(LOADING_STATUS(true));
    //const response = await newAgent.put(API.NEW_ARCHIVE_POST, data);
    const response = await newAgent.put(`${API.NEW_ARCHIVE_POST}/${data.id}`);
    if (response.status === 200) {
      dispatch(
        toasterStatusAction({ open: true, message: response.data.message })
      );
      dispatch(fetchPostList());
      dispatch(LOADING_STATUS(false));
    }
  } catch (error) {
    dispatch(LOADING_STATUS(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

// get Buying

export const getBuying = () => async (dispatch) => {
  try {
    dispatch(LOADING_STATUS(true));
    const response = await newAgent.get(API.NEW_GET_BUYING);
    if (response.status === 200) {
      // console.log("RESPONSE OF BUYING", response.data.data);
      dispatch(BUYING_LIST(response?.data?.data?.data));
      dispatch(POST_LIST_PAGINATION(response?.data?.data?.pagination));
      dispatch(LOADING_STATUS(false));
    }
  } catch (error) {
    dispatch(LOADING_STATUS(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const getSubscriptionList =
  (request, returnDataSubs) => async (dispatch) => {
    try {
      const response = await agent.get(API.SUBSCRIPTION_PLAN, request);
      dispatch(submittingRequestStatus(false));
      if (response.status === 200) {
        returnDataSubs(response.data.data);
      }
    } catch (error) {
      dispatch(submittingRequestStatus(false));
      dispatch(loadToasterData(error.message, "error", true));
    }
  };
export const getRemainingBoost =
  (request, returnDataRemaining) => async (dispatch) => {
    try {
      const response = await agent.get(API.REMAINING_BOOST, request);
      dispatch(submittingRequestStatus(false));
      if (response.status === 200) {
        returnDataRemaining(response.data.data);
      }
    } catch (error) {
      dispatch(submittingRequestStatus(false));
      dispatch(loadToasterData(error.message, "error", true));
    }
  };

export const applyBoost = (request) => async (dispatch) => {
  try {
    dispatch(submittingRequestStatus(true));
    const response = await agent.post(API.REMAINING_BOOST_APPLY, request);
    dispatch(submittingRequestStatus(false));
    //console.log("response.data.data",response.data.data)
    if (response.status === 200) {
      dispatch(loadToasterData(response.data.message, "success", true));
      /*  dispatch(
            toasterStatusAction({ open: true, message: response.data.message })
          );*/
      //returnSubscription(response.data.data);
    }
  } catch (error) {
    dispatch(submittingRequestStatus(false));
    dispatch(loadToasterData(error.message, "error", true));
  }
};

export const getCard = (request, returnDataCard) => async (dispatch) => {
  try {
    const response = await agent.get(API.CARD_LIST, request);
    dispatch(submittingRequestStatus(false));
    if (response.status === 200) {
      returnDataCard(response.data.data);
    }
  } catch (error) {
    dispatch(submittingRequestStatus(false));
    dispatch(loadToasterData(error.message, "error", true));
  }
};

// FETCH USER SOLD LIST
export function getUserSoldList(data) {
  return async (dispatch) => {
    dispatch(USER_SOLD_LIST_LOADER(true));
    await newAgent
      .get(`${API.NEW_GET_USER_SOLD_LIST}/${data.post_id}`)
      .then((response) => {
        if (response.status === 200) {
          // dispatch(
          //   toasterStatusAction({ open: true, message: response.data.message })
          // );
          dispatch(USER_SOLD_LIST_LOADER(false));
          dispatch(USER_SOLD_LIST(response.data.data));
        }
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
        dispatch(USER_SOLD_LIST_LOADER(false));
      });
  };
}

// HANDEL MARK AS SOLD
export function handelMarkAsSold(data, closeModal) {
  return async (dispatch) => {
    await newAgent
      .post(API.NEW_MARK_AS_SOLD, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            toasterStatusAction({ open: true, message: response.data.message })
          );
          dispatch(fetchPostList());
          closeModal();
          console.log("RESPOSNE", response.data);
        }
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
        console.log("ERROR", JSON.stringify(error));
      });
  };
}
