import { React, useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { StaticGoogleMap, Marker } from "react-static-google-map";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TextFormate } from "../../Component/TextFormate/TexFormate";

const PostPreview = (props) => {
  const { data, selectedFile, onEditSelectedFile } = props;
  console.log(data);
  const { t } = useTranslation();

  // All Selectors
  const { selectedLang } = useSelector((state) => ({
    selectedLang: state.selectedLang,
  }));

  const [currentImg, setCurrentImg] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (onEditSelectedFile.length !== 0) {
      setCurrentImg(onEditSelectedFile[0]?.url);
    } else {
      selectedFile.length !== 0 &&
        setCurrentImg(URL.createObjectURL(selectedFile[0]));
    }
  }, []);

  return (
    <>
      {data && data.id && (
        <section
          className={
            selectedLang == "ar"
              ? "is-arabic confirm_order_section"
              : "confirm_order_section"
          }
        >
          <Container>
            <div className="breadcrumb">
              <ul></ul>
            </div>

            <div className="confirm_order_wrapper">
              <Row>
                <Col lg={9}>
                  <div className="outline_wrapper">
                    <div className="image_gallery_sec">
                      {onEditSelectedFile.length !== 0 ? (
                        <img src={currentImg} alt="" />
                      ) : (
                        <img src={currentImg} alt="" />
                      )}
                    </div>

                    <div className="image-thubnail-gallery-outer">
                      {onEditSelectedFile &&
                        onEditSelectedFile.length !== 0 &&
                        onEditSelectedFile.map((item, index) => {
                          return (
                            <img
                              src={item?.url}
                              alt="No_Image"
                              style={
                                currentImg == item?.url
                                  ? {
                                      height: "80px",
                                      width: "80px",
                                      marginTop: "10px",
                                      marginLeft: "10px",
                                      // border: "3px solid #33a7cc",
                                      borderRadius: "10px",
                                      cursor: "pointer",
                                    }
                                  : {
                                      height: "80px",
                                      width: "80px",
                                      marginTop: "10px",
                                      marginLeft: "10px",
                                      borderRadius: "10px",
                                      cursor: "pointer",
                                    }
                              }
                              onClick={() => {
                                setCurrentImg(item?.url);
                                setCurrentIndex(index);
                              }}
                              key={index}
                            />
                          );
                        })}
                      {selectedFile &&
                        selectedFile?.length !== 0 &&
                        selectedFile?.map((item, index) => {
                          return (
                            <img
                              src={URL.createObjectURL(item)}
                              alt={t("NO_IMG")}
                              style={
                                index == currentIndex
                                  ? {
                                      height: "80px",
                                      width: "80px",
                                      marginTop: "10px",
                                      marginLeft: "10px",
                                      // border: "3px solid #33a7cc",
                                      borderRadius: "10px",
                                      cursor: "pointer",
                                    }
                                  : {
                                      height: "80px",
                                      width: "80px",
                                      marginTop: "10px",
                                      marginLeft: "10px",
                                      borderRadius: "10px",
                                      cursor: "pointer",
                                    }
                              }
                              onClick={() => {
                                setCurrentImg(URL.createObjectURL(item));
                                setCurrentIndex(index);
                              }}
                              key={index}
                            />
                          );
                        })}
                    </div>

                    <div className="confirm_order_info border-0">
                      <div className="product_item_horizantal align-items-">
                        <div className="d-flex align-items-center seller-post-review">
                          <div className="offer_product_detail">
                            <h3>{data?.title}</h3>
                            <div className="sel-post-date">
                              <span>
                                <i className="far fa-clock"></i>
                                {data.date}
                              </span>
                              <span>
                                <i className="fas fa-map-marker-alt"></i>
                                {data?.addresses && data?.addresses?.city}
                              </span>
                            </div>
                            <div className="post-review-item mt-3">
                              <small>{t("ITEM_PRICE")}</small>
                              <span className="price">
                                {TextFormate(data?.price, 2)} {t("SR")}
                                <span className="off_price">
                                  {data.discountPrice > 0 && data.discountPrice}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <span className="eye_icon">
                          <i className="fas fa-eye"></i>
                          {data?.view} {t("VIEW")}
                        </span>
                      </div>

                      <div className="listing_brands_new">
                        <div className="listing_brand_cat">
                          <ul>
                            <li>
                              <span className="preview-label">
                                {t("ITEM_CONDITION")}:
                              </span>
                              <span>{data?.conditions}</span>
                            </li>
                            <li>
                              <span className="preview-label">
                                {t("CATEGORY")}:
                              </span>
                              <span>{data?.categories}</span>
                            </li>
                          </ul>
                        </div>
                        <div className="listing_brand_cat">
                          <ul>
                            <li>
                              <span className="preview-label">
                                {t("BRAND")}:
                              </span>
                              <span>{data?.brands}</span>
                            </li>
                            <li>
                              <span className="preview-label">
                                {t("MODAL")}:
                              </span>
                              <span>{data?.models}</span>
                            </li>
                          </ul>
                        </div>

                        <div className="listing_brand_cat">
                          <ul>
                            <li>
                              <span className="preview-label">
                                {t("WEIGHT")}:
                              </span>
                              <span>
                                {data?.item_information?.weight}
                                {t("KG")}
                              </span>
                            </li>
                            <li>
                              <span className="preview-label">
                                {t("HEIGHT")}:
                              </span>
                              <span>
                                {data?.item_information?.height}
                                {t("cm")}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="listing_brand_cat">
                          <ul>
                            <li>
                              <span className="preview-label">
                                {t("LENGTH")}:
                              </span>
                              <span>
                                {data?.item_information?.length}
                                {t("CM")}
                              </span>
                            </li>
                            <li>
                              <span className="preview-label">
                                {t("WIDTH")}:
                              </span>
                              <span>
                                {data?.item_information?.width}
                                {t("CM")}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="listing_condition">
                        <div className="description">
                          <h3 className="seller_titles">{t("DESCRIPTION")}</h3>
                          <p>
                            {data?.description !== null
                              ? data?.description
                              : t("NO_DESCRIPTION")}
                            .{" "}
                          </p>
                        </div>
                      </div>

                      <div className="payment_method">
                        <div className="description d-flex justify-content-between align-items-end">
                          <div className="payment_social">
                            <ul className="m-0 p-0 d-flex">
                              <li>
                                {data?.favorite ? (
                                  <i className="fas fa-heart"></i>
                                ) : (
                                  <i className="far fa-heart"></i>
                                )}
                                {t("FAVOURITE")}
                              </li>
                              <li>
                                <i className="far fa-flag"></i>
                                {t("REPORT")}
                              </li>
                              <li>
                                <i className="fas fa-share"></i>
                                {t("SHARE")}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg={3}>
                  <div className="order_summary_sidebar post_review_side mb-4">
                    <div className="post_user_name p-0 bg-transparent border-0\">
                      <div className="order_summary_sidebar">
                        <h3>{t("ORDER_SUMMERY")}</h3>
                        <ul className="order_summary_list">
                          <li>
                            <span>{t("ITEM_PRICE_REVIEW")}:</span>
                            <strong>{data.price}</strong>
                          </li>

                          <li>
                            <span>{t("SHIPPING_FEE_REVIEW")}:</span>
                            <strong>{data.shipping_fee}</strong>
                          </li>
                          <li>
                            <span>
                              {t("SERVICE_FEE")}
                              <span className="d-inline-block px-2"></span>
                            </span>
                            <strong>{data.processing_fee}</strong>
                          </li>
                        </ul>

                        <div className="final_price">
                          <div className="final_costing_head">
                            <strong>{t("TOTAL_PAYOUT")}</strong>
                            <span className="price">{data.total} SR</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="order_summary_sidebar post_review_side mb-4">
                    <h3 className="bg-transparent p-0">{t("POSTED_IN")}</h3>
                    <small className="d-block mb-3 name">
                      <i className="fas fa-map-marker-alt" />
                      <span>{data?.addresses?.formatted_address}</span>
                    </small>

                    <figure>
                      <StaticGoogleMap
                        size="600x600"
                        className="img-fluid"
                        apiKey="AIzaSyD_knnAOAE98I9JhzBgtHR1a4J8t-u-opg"
                      >
                        <Marker
                          location={`${data.location[0]},${data.location[1]}`}
                          color="blue"
                        />
                      </StaticGoogleMap>
                    </figure>

                    <small className="d-block mt-1 name">
                      {t("RADIUS_COVER_10")}
                    </small>
                  </div>

                  <div>
                    <img
                      src="./assets/images/add.jpg"
                      alt=""
                      className="w-100"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      )}
    </>
  );
};
export default PostPreview;
