import io from "socket.io-client";
const { REACT_APP_SOCKET } = process.env;
let socket;
let newValue = false;
export const initiateSocket = (userId) => {
  socket = io(REACT_APP_SOCKET, {
    extraHeaders: {
      userId: userId,
    },
  });
  console.log(`Connecting socket...`);
};
export const disconnectSocket = () => {
  console.log("Disconnecting socket...");
  if (socket) socket.disconnect();
};
export const subscribeToChatListing = (cb) => {
  socket.on("buyerChatList", (msg) => {
    console.log(msg)
    return cb(null, msg.data ? msg.data : []);
  });
};
export const subscribeToMessageListing = (cb) => {
  if (newValue) {
    socket.on("dc-chat-details", (msg) => {
      console.log(msg)
      return cb(null, msg);
    });
  }
  else {
    socket.on("chat-details", (msg) => {
      return cb(null, msg);
    });
  }
};
export const subscribeToMessageDetails = (cb) => {
  console.log('subscribeToMessageDetails =>', cb);
  if (newValue) {
    socket.on("dcMessageDetails", (msg) => {
      console.log(msg)
      return cb(null, msg[0]);
    });
  }
  else {
    socket.on("messageDetails", (msg) => {
      return cb(null, msg[0]);
    });
  }
};
export const subscribeToRoomDetails = (cb) => {
  console.log('subscribeToRoomDetails =>', cb);
  if (newValue) {
    socket.on("dc-room-details", (msg) => {
      console.log(msg)
      return cb(null, msg);
    });
  }
  else {
    socket.on("room-details", (msg) => {
      return cb(null, msg);
    });
  }
};
export const subscribeToError = (cb) => {
  console.log('subscribeToError =>', cb);
  socket.on("error", (msg) => {
    console.log(msg)
    return cb(null, msg);
  });
};
export const subscribeToSuccessfully = (cb) => {
  console.log('subscribeToSuccessfully =>', cb);
  socket.on("successfully", (msg) => {
    console.log(msg)
    return cb(null, msg);
  });
};


export const subscribeDownloadLabel = (cb) => {
  if (newValue) {
    socket.on("downloadLabel", (msg) => {
      console.log(msg)
      return cb(null, msg);
    });
  }
  else {
    socket.on("downloadLabel", (msg) => {
      return cb(null, msg);
    });
  }
};


export const subscribShippingInformation = (cb) => {
  if (newValue) {
    socket.on("shippingInformation", (msg) => {
      console.log(msg)
      return cb(null, msg);
    });
  }
  else {
    socket.on("shippingInformation", (msg) => {
      return cb(null, msg);
    });
  }
};


export const subscribDropLocation = (cb) => {
  if (newValue) {
    socket.on("dropLocation", (msg) => {
      console.log(msg)
      return cb(null, msg);
    });
  }
  else {
    socket.on("dropLocation", (msg) => {
      return cb(null, msg);
    });
  }
};





export const sendMessage = (request) => {
  console.log('sendMessage =>', request);
  if (newValue) {
    console.log(socket.emit("send-message", request.room_id, request)); //dc-send-message
    if (socket) socket.emit("dc-send-message", request.room_id, request);
  }
  else {
    console.log(socket.emit("send-message", request.room_id, request)); //dc-send-message
    if (socket) socket.emit("send-message", request.room_id, request);
  }
};
export const chatList = (userId) => {
  if (socket) socket.emit("buyer-chat", userId);
};
export const joinRoom = (roomId,user_id) => {
  console.log('joinRoom =>', roomId,user_id);
  if (newValue) {
    if (socket) socket.emit("dc-join-room", roomId,user_id); //dc-join-room
  }
  else {
    if (socket) socket.emit("join-room", roomId,user_id); //dc-join-room
  }
};
export const messageListing = (roomId, userId) => {
  console.log('messageListing =>', roomId, userId);
  if (newValue) {
    console.log(roomId, userId)
    if (socket) socket.emit("dc-message-listing", roomId, userId); //dc-message-listing
  }
  else {
    console.log(roomId, userId)
    if (socket) socket.emit("message-listing", roomId, userId);
  }
};
export const sendCounterOffer = (request) => {
  console.log('sendCounterOffer =>', request);
  if (newValue) {
    if (socket) socket.emit("dc-send-counter-offer", request.room_id, request); //dc-offer-accept
  }
  else {
    if (socket) socket.emit("send-counter-offer", request.room_id, request);
  }
};

export const payHereOfferAccept = (request,history) => {
  console.log('newpayHereOfferAccept =>', request, history);
  console.log(request.room, request.data)
  if (newValue) {
    console.log('new value pay-here-offer-accept')
    if (socket) socket.emit("dc-pay-here-offer-accept", request.room, request);
    console.log(request.room, request)
    history.push(`/chat/${request.room}`);
  }
  else {
    console.log('pay-here-offer-accept else case')
   if (socket) socket.emit("pay-here-offer-accept", request.room, request);
  
   history.push(`/chat/${request.room}`);
  }
 
};


export const itemActionApprove = (request,history) => {
  console.log('item-action-approve =>', request, history);
  console.log(request.room, request.data)
  if (newValue) {
    console.log('new value item-action-approve')
    if (socket) socket.emit("item-action-approve", request.room, request);
    console.log(request.room, request)
    history.push(`/chat/${request.room}`);
  }
  else {
    console.log('itemActionApprove else case')
   if (socket) socket.emit("item-action-approve", request.room, request);
  
   history.push(`/chat/${request.room}`);
  }
 
};

export const cancelOfferRequest = (request) => {
  console.log('cancelOfferRequest => ', request);
  if (newValue) {
    if (socket) socket.emit("dc-offer-canceled", request.room_id, request);
  } else {
    if (socket) socket.emit("offer-canceled", request.room_id, request);
  }
};
export const acceptOfferRequest = (request) => {
  console.log('acceptOfferRequest =>', request);
  if (newValue) {
    if (socket) socket.emit("dc-offer-accept", request.room_id, request); //dc-offer-accept
  }
  else {
    if (socket) socket.emit("offer-accept", request.room_id, request); //dc-offer-accept
  }
};
export const acceptMeetLocationRequest = (request) => {
  console.log('acceptMeetLocationRequest => ', request);
  if (newValue) {
    if (socket) socket.emit("dc-confirm-meet-up", request.room_id, request);
  } else {
    if (socket) socket.emit("confirm-meet-up", request.room_id, request);
  }
};
export const paymentConfirm = (request) => {
  console.log('paymentConfirm => ', request);
  if (newValue) {
    if (socket) socket.emit("dc-paymentConfirm", request.room_id, request);
  } else {
    if (socket) socket.emit("paymentConfirm", request.room_id, request);
  }
};
export const printShippingLabel = (request) => {
  console.log('printShippingLabel => ', request);
  if (newValue) {
    if (socket) socket.emit("dc-create-shipping-label", request.room_id, request);
  } else {
    if (socket) socket.emit("create-shipping-label", request.room_id, request);
  }
};
export const pickupSocket = (request) => {
  console.log('pickupSocket => ', request);
  if (newValue) {
    if (socket) socket.emit("schedule-pick-up", request.room_id, request);
  } else {
    if (socket) socket.emit("schedule-pick-up", request.room_id, request);
  }
};
export const confirmOrderYes = (request) => {
  console.log('confirmOrderYes => ', request);
  if (newValue) {
    if (socket) socket.emit("dc-confirm-order-yes", request.room_id, request);
  } else {
    if (socket) socket.emit("confirm-order-yes", request.room_id, request);
  }
};
export const requestReject = (request) => {
  console.log('requestReject => ', request);
  if (newValue) {
    if (socket) socket.emit("dc-reject-request", request.room_id, request);
  } else {
    if (socket) socket.emit("reject-request", request.room_id, request);
  }
};
export const requestAccept = (request) => {
  console.log('requestAccept => ', request);
  if (newValue) {
    if (socket) socket.emit("dc=accept-request", request.room_id, request);
  } else {
    if (socket) socket.emit("accept-request", request.room_id, request);
  }
};
export const ShippingLabelBuyer = (request) => {
  console.log('ShippingLabelBuyer => ', request);
  if (newValue) {
    if (socket)
      socket.emit("dc-create-shipping-label-buyer", request.room_id, request);
  } else {
    if (socket)
      socket.emit("create-shipping-label-buyer", request.room_id, request);
  }
};
export const schedulePickupBuyer = (request) => {
  console.log('schedulePickupBuyer => ', request);
  if (newValue) {
    if (socket) socket.emit("dc-schedule-pick-up-buyer", request.room_id, request);
  } else {
    if (socket) socket.emit("schedule-pick-up-buyer", request.room_id, request);
  }
};
export const returnReceived = (request) => {
  console.log('returnReceived => ', request);
  if (newValue) {
    if (socket) socket.emit("dc-return-received", request.room_id, request);
  } else {
    if (socket) socket.emit("return-received", request.room_id, request);
  }
};


export const downloadLabel = (request) => {
  console.log('downloadLabel => ', request);
  if (newValue) {
    if (socket) socket.emit("download-label", request.room_id, request);
  } else {
    if (socket) socket.emit("download-label", request.room_id, request);
  }
};


export const pickupInformation = (request) => {
  console.log('pickupInformation => ', request);
  if (newValue) {
    if (socket) socket.emit("pickup-information", request.room_id, request);
  } else {
    if (socket) socket.emit("pickup-information", request.room_id, request);
  }
};

export const getDdropLocation = (request) => {
  console.log('getDdropLocation => ', request);
  if (newValue) {
    if (socket) socket.emit("get-drop-location", request.room_id, request);
  } else {
    if (socket) socket.emit("get-drop-location", request.room_id, request);
  }
};


export const itemActionReject = (request) => {
  console.log('item-action-reject => ', request);
  if (newValue) {
    if (socket) socket.emit("item-action-reject", request.room_id, request);
  } else {
    if (socket) socket.emit("item-action-reject", request.room_id, request);
  }
};





