import { React, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { sendOffer } from "../../actions/homePageActions";
import {
  sendCounterOffer,
  initiateSocket,
  joinRoom,
  disconnectSocket,
  payHereOfferAccept,
  subscribeToChatListing,
  subscribeToMessageListing
} from "../../utils/Socket";




import { useSelector } from "react-redux";
import { loadToasterData } from "../../actions/baseActions";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const queryString = require("query-string");



function Purchase(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const queryStringParsed = queryString.parse(location.search);
  let paymentObj =  {
    "PaymentId": queryStringParsed.PaymentId,
    "TranId": queryStringParsed.TranId,
    "ECI": queryStringParsed.ECI,
    "Result": queryStringParsed.Result,
    "TrackId": queryStringParsed.TrackId,
    "AuthCode": queryStringParsed.AuthCode,
    "ResponseCode": queryStringParsed.ResponseCode,
    "RRN": queryStringParsed.RRN,
    "responseHash": queryStringParsed.responseHash,
    "cardBrand": queryStringParsed.cardBrand,
    "amount":queryStringParsed.amount,
    "UserField1":queryStringParsed.UserField1,
    "UserField3":queryStringParsed.UserField3,
    "UserField4": queryStringParsed.UserField4,
    "UserField5":queryStringParsed.UserField5,
    "cardToken": queryStringParsed.cardToken,
    "maskedPAN":queryStringParsed.maskedPAN,
    "email":queryStringParsed.email,
    "payFor":queryStringParsed.payFor,
    "SubscriptionId": queryStringParsed.SubscriptionId,
    "PaymentType": queryStringParsed.PaymentType,
    "metaData":queryStringParsed.metaData,
    "udId1": "e4f5fc42-180b-4e6e-8fc8-44650d131bbc",
    "udId2": "64cce053e12ee850645252f0"
  }
  const { selectedLang, paymentData ,stateValue} = useSelector((state) => ({
   
    selectedLang: state.selectedLang,
    paymentData: state.paymentData,
    stateValue: state
  }));

  const { roomId } = props;
  console.log(stateValue);


  

  // All Selectors

 

  useEffect(() => {
    initiateSocket(paymentData.user_id);
    if (paymentData && paymentData.room_id) {
      joinRoom(paymentData && paymentData.room_id);
    }
    return () => {
      disconnectSocket();
    };
  }, [paymentData]);
  console.log('paymentData => ', paymentData,
    selectedLang,)
  const messageid = paymentData.chatMessage.filter(x => x.buyer_status === "PAY_HERE_OFFER" || x.buyer_status === "SYSTEM_CARD");
  const message_id = messageid[0]._id;
  console.log(message_id)
  // All Selectors
  useEffect(() => {
    if (queryStringParsed.Result === "Failure") {
      dispatch(loadToasterData(t("PAYMENT_FAILED"), "error", true));
      setTimeout(function () {
        if (paymentData && paymentData.authorize_type === "Counter") {
          history.push(`/chat/${paymentData.room_id}`);
        } else if (
          paymentData &&
          paymentData.authorize_type === "Seller_Counter"
        ) {
          history.push(`/manage`);
        } else {
          history.push(`/product/${paymentData.post_id}`);
        }
      }, 1000);
    } else {
      if (paymentData && paymentData.authorize_type === "Counter") {
        var request = {
          user_id: paymentData.user_id,
          room_id: paymentData.room_id,
          post_id: paymentData.post_id,
          amount: paymentData.amount,
          paymentType: paymentData.payment_type,
          paymentId: queryStringParsed.PaymentId,
          cardId: paymentData.card_id,
          language: selectedLang,
        };

        setTimeout(function () {
          sendCounterOffer(request);
          history.push(`/chat/${paymentData.room_id}`);
        }, 2000);
      } else if (
        paymentData &&
        paymentData.authorize_type === "Seller_Counter"
      ) {
        const request = {
          user_id: paymentData.user_id,
          room_id: paymentData.room_id,
          post_id: paymentData.post_id,
          amount: paymentData.amount,
          paymentType: paymentData.payment_type,
          paymentId: queryStringParsed.PaymentId,
          cardId: paymentData.card_id,
          language: selectedLang,
        };
        sendCounterOffer(request);
        setTimeout(function () {
          history.push(`/manage`);
          //  window.href.location = "/manage";
        }, 1000);
      } else {
        var paymentType = "ONLINE";
        // const request = {
        //   post_id: paymentData.post_id,
        //   amount: paymentData.amount,
        //   code: paymentData.coupon_code,
        //   address_id: paymentData.address_id,
        //   shipping: paymentData.shipping,
        //   meetUp: paymentData.meetUp,
        //   paymentType: paymentType,
        //   room_id: paymentData.room_id,
        //   paymentId: queryStringParsed.PaymentId,
        //   cardId: paymentData.card_id,
        //   useWallet: paymentData.useWallet,
        //   paymentObject: queryStringParsed
        // };

        var paymentType = "ONLINE";
        const request = {         
            tranId: queryStringParsed.TranId,
            cardId: paymentData.card_id,
            paymentId: queryStringParsed.PaymentId,
            message_id:message_id,
            language: selectedLang,
            user_id: paymentData.user_id,
            useWallet: paymentData.useWallet,
            //paymentObject: queryStringParsed,
            paymentObject: paymentObj,
            paymentType: paymentType,
            room: paymentData.room_id,
          
        }
        //dispatch(sendOffer(request, updateOffer, history));

        console.log(request,history)
        payHereOfferAccept(request, history)
      }
    }
  }, []);

  useEffect(() => {
    initiateSocket(paymentData.user_id);
    joinRoom(paymentData.room_id);
    return () => {
      disconnectSocket();
    };
  }, [paymentData.room_id, paymentData.user_id]);

  const updateOffer = (data) => {
    if (data) {
      //dispatch(loadToasterData(data.message, "success", true));
      history.push(`/chat/${data.room}`);
    } else {
      //   dispatch(loadToasterData(data.message, "error", true));
      history.push(`/`);
    }
  };
  /* useEffect(() => {
    subscribeToSuccessfully((err, data) => {
      // if (err) return;
      dispatch(loadToasterData(data.message, "success", true));
    });

    subscribeToError((err, data) => {
      // if (err) return;
      dispatch(loadToasterData(data.message, "error", true));
      //history.push(`/`);
    });
  }, []); */
  return (
    <div>
      <Backdrop
        sx={{ color: "#00BFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="section-b-space light-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="success-text">
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                <h2>{t("THANK_YOU")}</h2>
                <p>{t("THANK_YOU_MESSAGE")}</p>
                <p>
                  {t("TRANSACTION_ID")}:{queryStringParsed.TranId}
                </p>
                {/* <p>Order ID:{getOrderID.order_id}</p>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Purchase;
