import { React, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  joinRoom,
  paymentConfirm,
  initiateSocket,
  disconnectSocket,
} from "../../utils/Socket";
import { loadToasterData } from "../../actions/baseActions";
const queryString = require("query-string");
function OrderSuccess(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { selectedLang, paymentData } = useSelector((state) => ({
    selectedLang: state.selectedLang,
    paymentData: state.paymentData,
  }));

  const queryStringParsed = queryString.parse(location.search);
  // All Selectors
  useEffect(() => {
    if (queryStringParsed.Result === "Failure") {
      dispatch(loadToasterData(t("PAYMENT_FAILED"), "error", true));
      history.push(`/chat/${paymentData.room_id}`);
    } else {
      var request = {
        user_id: paymentData.user_id,
        order_id: paymentData.order_id,
        room_id: paymentData.room_id,
        tranId: queryStringParsed.TranId,
        paymentId: queryStringParsed.PaymentId,
        cardId: paymentData.cardId,
        language: selectedLang,
        useWallet: paymentData.useWallet,
      };
      paymentConfirm(request);
      setTimeout(function () {
        history.push(`/chat/${paymentData.room_id}`);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    initiateSocket(paymentData.user_id);
    joinRoom(paymentData.room_id,paymentData.user_id);
    return () => {
      disconnectSocket();
    };
  }, [paymentData.room_id, paymentData.user_id]);

  return (
    <div>
      <section className="section-b-space light-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="success-text">
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                <h2>{t("THANK_YOU")}</h2>
                <p>{t("THANK_YOU_MESSAGE")}</p>
                <p>
                  {t("TRANSACTION_ID")}:{queryStringParsed.TranId}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OrderSuccess;
