import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function deleteAccountVerify(
  state = initialState.deleteAccountVerify,
  action
) {
  switch (action.type) {
    case types.LOADED_DELETE_ACCOUNT_VERIFY:
      return action.deleteAccountVerify;
    default:
      return state;
  }
}
