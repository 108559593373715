import React, { useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { useForm } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import { Divider, Tooltip } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import Pagination from "@mui/material/Pagination";
import LottiLoader from "../../../Component/lottiAnimation/LottiLoader";
import { deletePost, getArchiveList } from "../../../actions/SIdebarAction";
import { textTruncate } from "../../../Component/TextTruncate/textTruncate";
import { TextFormate } from "../../../Component/TextFormate/TexFormate";
import { getEditPostSell } from "../../../actions/UserAction";

function ArchiveList() {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    values,
  } = useForm();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const dispatch = useDispatch();

  //  All Selectors
  const {
    GET_USER_PROFILE,
    GET_ARCHIVE_DATA,
    ARCHIVE_LIST_PAGINATION,
    LOADING,
  } = useSelector((state) => ({
    GET_USER_PROFILE: state?.sidebarReducer?.userProfileData,
    GET_ARCHIVE_DATA: state?.sidebarReducer?.archivePostList,
    ARCHIVE_LIST_PAGINATION: state?.sidebarReducer?.archivePostPagination,
    LOADING: state?.sidebarReducer?.archivePostLoading,
  }));

  useEffect(() => {
    const fetchArchiveList = () => {
      dispatch(getArchiveList());
    };
    fetchArchiveList();
  }, []);

  const hendelDeletePost = (id) => {
    dispatch(deletePost(id));
  };
  const handleSell = (id) => {
    history.push(`/sell-another/${id}`);
  };
  const fetchEditPostData = (id) => {
    dispatch(getEditPostSell(id, history));
  };
  return (
    <div>
      <section
        className="middle_banner_sec"
        style={{
          backgroundImage: `url(${GET_USER_PROFILE?.backgroundImage})`,
        }}
      ></section>

      <section className="manage_address">
        <Container>
          <div className="sidebar_outer">
            <div className="sidebar_main">
              <Sidebar />
            </div>

            <div className="dashboard_main">
              <div className="outline_wrapper">
                <div
                  className="mb-5"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2 style={{ marginBottom: "0px", marginTop: "15px" }}>
                    {t("MANAGE_ARCHIVE_LIST")}
                  </h2>
                </div>
                {LOADING ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <LottiLoader loader={true} height={150} width={150} />
                  </div>
                ) : (
                  <>
                    {!LOADING && GET_ARCHIVE_DATA?.length === 0 && (
                      <Row>
                        <LottiLoader
                          message="No Post Found"
                          height={250}
                          width={250}
                        />
                      </Row>
                    )}

                    <Row>
                      {GET_ARCHIVE_DATA &&
                        GET_ARCHIVE_DATA?.length !== 0 &&
                        GET_ARCHIVE_DATA?.map((item) => {
                          return (
                            <Col sm={6} key={item?.id}>
                              <div
                                className="order_detail_box"
                                style={{ height: "200px" }}
                              >
                                <>
                                  <div className="order_detail_head">
                                    <p
                                      style={{
                                        fontSize: "19px",
                                        fontWeight: "bold",
                                        margin: "0px",
                                      }}
                                    >
                                      {textTruncate(item?.title)}{" "}
                                    </p>
                                    {/* <div className="non-semantic-protector">
                                      <h5 className="ribbon">
                                        <div
                                          className="ribbon-content "
                                          style={{
                                            fontWeight: "100",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {t("BOOST_DURATION")} :{item?.day}{" "}
                                          {t("DAYS_LEFT")}
                                        </div>
                                      </h5>
                                    </div> */}

                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                      >
                                        <img
                                          src="../assets/images/toggle_icon1.svg"
                                          alt=""
                                          style={{ width: "4px" }}
                                        />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href="#"
                                          onClick={() =>
                                            hendelDeletePost(item?.id)
                                          }
                                        >
                                          {t("DELETE")}{" "}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#/"
                                          //onClick={() => handleSell(item?._id)}
                                          onClick={() =>
                                            fetchEditPostData(item?.id)
                                          }
                                        >
                                          {t("SELL_ANOTHER")}{" "}
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </>

                                <div
                                  className="order_caption"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    history.push("/product/" + item?.id);
                                  }}
                                >
                                  <Row>
                                    <Col
                                      md={4}
                                      style={{ position: "relative" }}
                                    >
                                      <img
                                        src={item?.image}
                                        alt="No_Img"
                                        className="manage-post-img"
                                      />
                                    </Col>

                                    <Col md={8}>
                                      <p> {item?.offer} </p>{" "}
                                      {item?.boost && (
                                        <p>
                                          <span
                                            className="boost_badge"
                                            style={{ padding: "5px" }}
                                          >
                                            {t("DOFFO_BOOST")}
                                          </span>
                                        </p>
                                      )}
                                      <p>
                                        <strong>{t("PRICE")} :</strong>{" "}
                                        {TextFormate(item?.price, 2)} {t("SR")}
                                      </p>
                                      <p>
                                        {" "}
                                        <span className="eye_icon">
                                          <i className="fas fa-eye"></i>
                                          {item?.view} {t("VIEW")}
                                        </span>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                    </Row>
                  </>
                )}
                {/* {!LOADING && GET_ARCHIVE_DATA.length !== 0 && (
                  <>
                    <Divider />
                    <div className="d-flex justify-content-between mb-4 mt-4">
                      <p>
                        <strong>{t("TOTAL_ITEM")}</strong> :{" "}
                        <span>{ARCHIVE_LIST_PAGINATION?.total}</span>
                      </p>
                      <Pagination
                        //   page={page}
                        count={ARCHIVE_LIST_PAGINATION?.total_pages}
                        color="primary"
                        variant="outlined"
                        //   onChange={(e, value) => pagination(e, value)}
                      />
                    </div>
                  </>
                )} */}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}

export default ArchiveList;
