import { Fragment, lazy, React, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  loadBuyNowDetails,
  getHomeProductDetail,
} from "../../actions/HomeAction";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
function BuyNow(props) {
  const { product } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const productId = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  const [currentImg, setCurrentImg] = useState({});
  const [productLocation, setProductLocation] = useState([]);
  // All Selectors
  const { selectedLang, PRODUCT_DETAIL, USER_INFO, dialog, LOADING_REPORT } =
    useSelector((state) => ({
      dialog: state.dialog,
      selectedLang: state.selectedLang,
      PRODUCT_DETAIL: state.homeReducer.homeProductDetails,
      USER_INFO: state.userInfo,
      LOADING_REPORT: state?.homeReducer?.submitReasonLoader,
    }));
  useEffect(() => {
    const data = { id: productId };
    dispatch(getHomeProductDetail(data, setCurrentImg, setProductLocation));
  }, [dispatch, productId]);
  var amountDiscount = localStorage.getItem("amount");
  var appliedCoupon = localStorage.getItem("coupon");
  var card_tokens = localStorage.getItem("card_token");
  useEffect(() => {
    var first_name = USER_INFO.first_name;
    var lastName = USER_INFO.last_name;
    var phoneNumber = USER_INFO.mobile_number;
    var customerEmail = USER_INFO.email;
    var amount = amountDiscount;
    var card_token = card_tokens;

    const request = {
      first_name,
      lastName,
      phoneNumber,
      customerEmail,
      amount,
      card_token,
    };
    dispatch(loadBuyNowDetails(request, returnData));
  }, [dispatch, USER_INFO, amountDiscount, card_tokens]);
  const [getDataUrl, setDataUrl] = useState("");
  const returnData = (data) => {
    setDataUrl(data);
  };
  const ref = useRef(null);
  useEffect(() => {
    if (getDataUrl !== "") {
      ref.current.click();
    }
  }, [getDataUrl]);
  const [open, setOpen] = useState(true);
  localStorage.setItem("post_id", PRODUCT_DETAIL.id);
  return (
    <div className="container">
      <Backdrop
        sx={{ color: "#00BFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {getDataUrl !== "" && (
        <form
          name="pymForm"
          method="POST"
          action={getDataUrl}
          style={{ display: "none" }}
        >
          <h1>Transaction is processing.....</h1>
          <button ref={ref} type="submit">
            Submit
          </button>
        </form>
      )}
    </div>
  );
}

export default BuyNow;
