import { React, useState } from "react";
import { Modal, Col, Row, Form } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { crose } from "../../assets/images/index";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import { Box, IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import moment from "moment";
import SubmitButton from "../../Component/Button";
import { useForm } from "react-hook-form";
import { loadToasterData } from "../../actions/baseActions";
import { saveMeetUpDetails } from "../../actions/SIdebarAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "@hookform/error-message";
import Maps from "./googleMap";
const Popup = (props) => {
  const dispatch = useDispatch();
  const { item, show, onHide, onLoadMessage } = props;
  console.log(props);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(moment());
  const [locationName, setLocationName] = useState(null);
  const [landMark, setLandMark] = useState(null);
  const [lat, setLat] = useState(21.663473233438076);
  const [lng, setLng] = useState(39.17243320159806);
  const [area, setArea] = useState(
    "King Abdulaziz International Airport"
  );
  const { t } = useTranslation();
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  console.log("LAT", lat);
  console.log("LANG", lng);
  console.log("AREA", area);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});

  const onSubmit = (data) => {
    if (lat === 26.8553377 && lng === 75.8147433) {
      dispatch(loadToasterData(t("PLEASE_SELECT_LOCATION"), "error", true));
    } else {
      const request = {
        order_id: item?.item?.orderId,
        location_name: data.location_name,
        landmark: data.landmark,
        date: moment(selectedDate).format("DD-MM-YYYY"),
        time: moment(selectedTime).format("HH:mm"),
        lat: lat,
        log: lng,
      };
      dispatch(saveMeetUpDetails(request));
      onLoadMessage();
    }
  };
  const handleTimeChange = (data) => {
    setSelectedTime(data);
  };

  return (
    <>
      {/* Set Your Location Start */}
      <Modal
        show={show}
        onHide={onHide}
        centered
        className="location_modal schedule-meetup-modal"
      >
        <button className="crose_btn" onClick={onHide}>
          <img src={crose} alt="crose" />
        </button>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <Modal.Body>
            <Modal.Title>{t("SCHEDULE_MEETUP")}</Modal.Title>

            <div className="addressmap">
              <Maps
                lat={lat}
                setLat={setLat}
                lng={lng}
                setLng={setLng}
                area={area}
                setArea={setArea}
              ></Maps>
            </div>
            <br />

            <Row>
              <Col sm={12}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={locationName}
                    onChange={(e) => setLocationName(e.target.value)}
                    placeholder={t("LOCATION_NAME")}
                    name="location_name"
                    {...register("location_name", {
                      required: t("PLEASE_ENTER_LOCATION"),
                    })}
                    style={{ paddingLeft: "15px", marginBottom: "15px" }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="location_name"
                    render={({ message }) => (
                      <p className="error-message">{message}</p>
                    )}
                  />
                </Form.Group>
              </Col>

              <Col sm={12}>
                <Form.Group className="mb-3 form_field" controlId="">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <KeyboardDatePicker
                        autoOk
                        style={{ width: "100%" }}
                        disableToolbar
                        inputVariant="outlined"
                        fullWidth
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        value={selectedDate}
                        minDate={new Date()}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        keyboardIcon={<Box component={EventIcon} />}
                        placeholder={t("DATE")}
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={(e) => {}}>
                              <HighlightOffIcon />
                            </IconButton>
                          ),
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Form.Group>
              </Col>

              <Col sm={12}>
                <Form.Group className="mb-3 form_field" controlId="">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container>
                      <TimePicker
                        style={{ width: "100%" }}
                        value={selectedTime}
                        inputVariant="outlined"
                        fullWidth
                        minDate={moment()}
                        onChange={handleTimeChange}
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={(e) => {}}>
                              <HighlightOffIcon />
                            </IconButton>
                          ),
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group>
              <Form.Control
                type="text"
                value={landMark}
                onChange={(e) => setLandMark(e.target.value)}
                placeholder={t("NEARBY_LANDMARK")}
                name="landmark"
                {...register("landmark", {
                  required: t("PLEASE_ENTER_LANDMARK"),
                })}
                style={{ paddingLeft: "15px" }}
              />
              <ErrorMessage
                errors={errors}
                name="landmark"
                render={({ message }) => (
                  <p className="error-message">{message}</p>
                )}
              />
            </Form.Group>
            <br />
            <SubmitButton title={t("SUBMIT")} style={{ width: "100%" }} />
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

export default Popup;
