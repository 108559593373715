import { React, Fragment, useEffect, lazy, useState } from "react";
import LangSelector from "../../Component/LangSelector";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { userLogout } from "../../actions/userActions";
import {
  loadLoginPop,
  loadRegisterPop,
  loadDialog,
} from "../../actions/baseActions";
import { Link, useHistory } from "react-router-dom";

import {
  toggle,
  Logo,
  Gride,
  Notification,
  Location,
  user,
} from "../../assets/images/index";
import { Container, FormControl, Button } from "react-bootstrap";
import { IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { fetchHomeProductList } from "../../actions/HomeAction";
import LocationModel from "./LocationModel";
const RegisterModel = lazy(() => import("../Auth/Register"));
const LoginModel = lazy(() => import("../Auth/SignIn"));
const SocialLoginAccountModel = lazy(() =>
  import("../Auth/SocialLoginAccount")
);
const ForgotPassword = lazy(() => import("../Auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../Auth/RestPassword"));
const RegisterVerify = lazy(() => import("../Auth/RegisterVerify"));
const Dialog = lazy(() => import("../../Component/Dialog"));
const DeleteAccountVerify = lazy(() => import("../Auth/DeleteAccountVerify"));


const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  document.getElementById("onscroll").onscroll = function () {
    var element = document.getElementById("header_top");
    var scroll = window.scrollY;
    if (scroll <= 20) {
      element && element.classList.remove("fixed-top");
    } else {
      element && element.classList.add("fixed-top");
    }
  };

  const showMobileToggle = () => {
    document.getElementById("header_top").classList.toggle("showMobileNav");
  };

  // All Selectors
  const {
    loginPopup,
    registerPopup,
    forgotPassPopup,
    resetPass,
    registerVerify,
    selectedLang,
    isAuth,
    userInfo,
    dialog,
    socialParamsPopup,
    deleteAccountVerify
  } = useSelector((state) => ({
    loginPopup: state.loginPopup,
    registerPopup: state.registerPopup,
    forgotPassPopup: state.forgotPassPopup,
    resetPass: state.resetPass,
    registerVerify: state.registerVerify,
    selectedLang: state.selectedLang,
    isAuth: state.isAuth,
    userInfo: state.userInfo,
    dialog: state.dialog,
    socialParamsPopup: state.socialParamsPopup,
    deleteAccountVerify: state.deleteAccountVerify
  }));

  // All State
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [activeDefaultTab] = useState("all");

  const handleShowLogin = () => {
    dispatch(loadLoginPop(true));
  };
  const handleShowRegister = () => {
    dispatch(loadRegisterPop(true));
  };

  useEffect(() => {
    if (selectedLang === "ar") {
      document.getElementsByTagName("HTML")[0].setAttribute("dir", "rtl");
      document.getElementsByTagName("HTML")[0].classList.add("arabic");
    } else {
      document.getElementsByTagName("HTML")[0].setAttribute("dir", "ltr");
      document.getElementsByTagName("HTML")[0].classList.remove("arabic");
    }
  }, [selectedLang]);

  const handleLogoutAction = () => {
    dispatch(
      loadDialog({
        open: false,
        message: t("LOGOUT_MESSAGE"),
        title: t("LOGOUT_TITLE"),
      })
    );
    dispatch(userLogout());
  };

  const handleNotification = () => {
    if (!isAuth) {
      dispatch(loadLoginPop(true));
    } else {
      history.push("/notifications");
    }
  };

  const toggleLocationModal = () => {
    setShowLocationModal(!showLocationModal);
  };

  const hendelSearch = () => {
    let data = { type: activeDefaultTab, page: 1, keyword: keyword };
    dispatch(fetchHomeProductList(data));

    // window.scroll(500, 0);
    window.scroll({ top: 650, behavior: "smooth" });
  };

  const hendelResetSearch = () => {
    setKeyword("");
    let data = { type: activeDefaultTab, page: 1 };
    dispatch(fetchHomeProductList(data));
  };
  // var userLat = localStorage.getItem("userLat");
  //var userLang = localStorage.getItem("userLang");
  var userCity = localStorage.getItem("userCity");

  return (
    <Fragment>
      <header id="header_top">
        <Container fluid>
          <div className="header_contant">
            <Button
              className="for_mobile Mobile_toggle"
              onClick={() => showMobileToggle()}
            >
              <img src={toggle} alt="toggle" />
            </Button>
            <Link to="/" className="nav-brand">
              <img src={Logo} alt="logo" />
            </Link>

            <div className="header_list select_market">
              <span className="gride_mrketing">
                <img src={Gride} alt="Gride" />
                {t("SHOP_MARKETPLACE")}
              </span>
            </div>

            <div className="header_list header_search_form">
              <span
                className="location-picker"
                onClick={() => toggleLocationModal()}
              >
                <img src={Location} alt="Location" />
                {userCity && userCity !== null ? userCity : t("LOCATION")}
              </span>

              <FormControl
                placeholder={t("SEARCH_PLACEHOLDER")}
                name="setKeyword"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <span className="btn btn_search">
                <span
                  style={
                    !keyword
                      ? { display: "flex", cursor: "not-allowed" }
                      : { display: "flex" }
                  }
                >
                  <IconButton
                    onClick={() => hendelSearch()}
                    disabled={!keyword}
                  >
                    <SearchIcon style={{ color: "#46BADF" }} />
                  </IconButton>
                  {keyword && (
                    <IconButton onClick={() => hendelResetSearch()}>
                      <ClearIcon style={{ color: "#46BADF" }} />
                    </IconButton>
                  )}
                </span>
              </span>
              {/* <button className="btn btn_search">
                <img src={Search} alt="search" />
              </button> */}
            </div>

            <div className="header_list header_droap_box">
              {/* <Dropdown className="notification">
                <Dropdown.Toggle>
                  <img
                    src={Notification}
                    alt="notification"
                    onClick={() => handleNotification()}
                  />
                </Dropdown.Toggle>
              </Dropdown> */}
              <img
                src={Notification}
                alt="notification"
                onClick={() => handleNotification()}
                style={{ cursor: "pointer" }}
              />
            </div>

            <div className="formsticky" id="navtoggleid">
              <Button
                className="header_list theme_btn"
                onClick={() => history.push("/manage-post-list")}
              >
                + {t("SELL_ON_DOFFO")}
              </Button>
              {!isAuth && (
                <div className="header_list subscription">
                  <button
                    className="subscription_link outline_btn"
                    onClick={handleShowLogin}
                  >
                    {t("LOGIN")}
                  </button>
                  <button
                    className="subscription_link outline_btn"
                    onClick={handleShowRegister}
                  >
                    {t("SIGN_UP")}
                  </button>
                </div>
              )}
              {isAuth && (
                <div>
                  <div
                    className="after_login choose-language"
                    onClick={() => history.push("/account-info")}
                  >
                    <span className="user_name">{`${userInfo.first_name}`}</span>
                    <span className="user_pic">
                      <img src={user} alt="user" />
                    </span>
                  </div>
                </div>
              )}
              {/* {isAuth && (
                <div className="header_list subscription">
                  <button
                    className="subscription_link outline_btn"
                    onClick={handleLogoutClick}
                  >
                    {t("LOGOUT")}
                  </button>
                </div>
              )} */}

              <div className="header_list languge">
                <LangSelector />
              </div>
            </div>
          </div>
        </Container>
      </header>
      {loginPopup && <LoginModel show={loginPopup} />}
      {socialParamsPopup && (
        <SocialLoginAccountModel show={socialParamsPopup} />
      )}
      {registerPopup && <RegisterModel show={registerPopup} />}
      {forgotPassPopup && <ForgotPassword show={forgotPassPopup} />}
      {resetPass && <ResetPassword show={resetPass} />}
      {registerVerify && <RegisterVerify show={registerVerify} />}
      {deleteAccountVerify && <DeleteAccountVerify show={deleteAccountVerify} />}
      {dialog.open && <Dialog action={handleLogoutAction} />}
      {showLocationModal && (
        <LocationModel
          show={showLocationModal}
          onHide={() => toggleLocationModal()}
        />
      )}
    </Fragment>
  );
};
export default Header;
