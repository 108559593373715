import React, { useEffect, useState } from "react";
import { Row, Container, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import {
  banner,
  Manage,
  Plus,
  Message,
  Like,
  All,
  brnd,
  Pickup,
  Shipping,
} from "../../assets/images/index";
import filterImg from "../../assets/images/filters.svg";
import CustomTabs from "../../Component/Tabs/Tabs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHomeProductList,
  fetchHomeProductOnLoadMore,
  getCategoryList,
} from "../../actions/HomeAction";
import { addCard, deleteCard } from "../../actions/bankAction";
import { TextFormate } from "../../Component/TextFormate/TexFormate";
import LottiLoader from "../../Component/lottiAnimation/LottiLoader";
import Fade from "react-reveal/Fade";
import Slider from "@mui/material/Slider";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { FloatInput } from "../../Component/Input/inputValidations";
import Select from "@material-ui/core/Select";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const queryString = require("query-string");

function Home(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { push } = useHistory();

  const queryStringParsed = queryString.parse(props.location.search);

  function slidetoggle() {
    document.getElementById("toggleshow").classList.toggle("newtoggle");
  }
  const marks = [
    {
      value: 0,
      label: "0 Km",
    },

    {
      value: 100,
      label: "100 Km",
    },
  ];

  // All Selectors
  const {
    selectedLang,
    GET_HOME_PRODUCT_LIST,
    LOADING,
    GET_HOME_PRODUCT_PAGINATION,
    LOAD_MORE_LOADING,
  } = useSelector((state) => ({
    selectedLang: state?.selectedLang,
    GET_HOME_PRODUCT_LIST: state?.homeReducer?.homeProductList,
    LOADING: state?.homeReducer?.homeProductLoading,
    GET_HOME_PRODUCT_PAGINATION: state?.homeReducer?.homeProductPagination,
    LOAD_MORE_LOADING: state?.homeReducer?.homeProductLoadMoreLoading,
  }));

  // All state
  const customTab = [
    { eventKey: "all", title: t("ALL"), image: All },
    { eventKey: "meetup", title: t("MEETUP"), image: Pickup },
    { eventKey: "shipping", title: t("SHIPPING"), image: Shipping },
  ];
  const [activeDefaultTab, setActiveDefaultTab] = useState("all");
  const [page, setPage] = useState(1);
  const [showReveal, setShowReveal] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [direction, setDirection] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [sliderDirection, setSliderDirection] = useState("");

  const onSelectTab = (e) => {
    console.log(e);
    setActiveDefaultTab(e);

    let data = {};
    setShowReveal(false);
    setPage(1);
    if (e === "meetup") {
      data = {
        type: "pickup",
        page: 1,

        keyword: null,
        category: null,
        deviceToken: null,
        city: null,
        viewAll: false,
        minPrice: null,
        maxPrice: null,
        distance: 0,
        coordinates: [],
      };
    } else {
      data = {
        type: e,
        page: 1,

        keyword: null,
        category: null,
        deviceToken: null,
        city: null,
        viewAll: false,
        minPrice: null,
        maxPrice: null,
        distance: 0,
        coordinates: [],
      };
    }
    dispatch(fetchHomeProductList(data));
  };

  useEffect(() => {
    if (
      Object.keys(queryStringParsed).length > 0 &&
      queryStringParsed.card_id === undefined
    ) {
      dispatch(
        addCard(
          {
            tranId: queryStringParsed.TranId,
            payId: queryStringParsed.PaymentId,
            cardToken: queryStringParsed.cardToken,
            cardBrand: queryStringParsed.cardBrand,
            maskedCardNo: queryStringParsed.maskedPAN,
          },
          push
        )
      );
    } else if (
      queryStringParsed.card_id !== undefined &&
      queryStringParsed.card_id !== ""
    ) {
      dispatch(deleteCard({ id: queryStringParsed.card_id }, push));
    }
    const fetchProduct = () => {
      let data = {
        type: activeDefaultTab,
        page: page,
        keyword: null,
        category: null,
        deviceToken: null,
        city: null,
        viewAll: false,
        minPrice: null,
        maxPrice: null,
        distance: 0,
        coordinates: [],
      };
      dispatch(fetchHomeProductList(data));
    };
    fetchProduct();
  }, []);

  const handelLoadMore = () => {
    setPage(page + 1);
    let data = {};
    if (activeDefaultTab == "meetup") {
      data = {
        type: "pickup",
        page: page + 1,
        sort: sortBy,
        direction: direction,
        minPrice: minPrice,
        maxPrice: maxPrice,
        distance: sliderDirection,
      };
    } else {
      data = {
        type: activeDefaultTab,
        page: page + 1,
        sort: sortBy,
        direction: direction,
        minPrice: minPrice,
        maxPrice: maxPrice,
        distance: sliderDirection,
      };
    }
    dispatch(fetchHomeProductOnLoadMore(data));
  };
  //  const [getSortBy, setSortBy] = useState(false);
  const handelChangeSortBy = (e) => {
    const value = e.target.value;
    if (value == "newestToOldest") {
      setSortBy("created_at");
      setDirection("desc");
      //  setSortBy(value);
    }
    if (value == "oldestToNewest") {
      setSortBy("created_at");
      setDirection("asc");
    }
    if (value == "lowToHigh") {
      setSortBy("price");
      setDirection("asc");
    }
    if (value == "highToLow") {
      setSortBy("price");
      setDirection("desc");
    }
    if (value == "nearestToBuyer") {
      setSortBy("calculated");
      setDirection("asc");
    }
    if (value == "furthestToBuyer") {
      setSortBy("calculated");
      setDirection("desc");
    }
  };

  const sliderChange = (e, newValue) => {
    setSliderDirection(newValue);
  };

  const resetForm = () => {
    setSortBy("");
    setDirection("");
    setMinPrice("");
    setMaxPrice("");
    setSliderDirection("");
    setShowReveal(!showReveal);
    let data = {};

    if (activeDefaultTab == "meetup") {
      data = {
        type: "pickup",
        page: 1,

        keyword: null,
        category: null,
        deviceToken: null,
        city: null,
        viewAll: false,
        minPrice: null,
        maxPrice: null,
        distance: 0,
        coordinates: [],
      };
    } else {
      data = {
        type: activeDefaultTab,
        page: 1,

        keyword: null,
        category: null,
        deviceToken: null,
        city: null,
        viewAll: false,
        minPrice: null,
        maxPrice: null,
        distance: 0,
        coordinates: [],
      };
    }

    dispatch(fetchHomeProductList(data));
  };
  /* const [getCurrentLatLang, setCurrentLatLang] = useState([]);
  if (
    localStorage.getItem("userLat") &&
    localStorage.getItem("userLat") !== null
  ) {
    setCurrentLatLang(localStorage.getItem("userLat"));
  } else {
    setCurrentLatLang(26.8634705);
  }
  if (
    localStorage.getItem("userLang") &&
    localStorage.getItem("userLang") !== null
  ) {
    setCurrentLatLang(localStorage.getItem("userLang"));
  } else {
    setCurrentLatLang(75.81852);
  }
  console.log(getCurrentLatLang);*/
  var lat = localStorage.getItem("userLat")
    ? localStorage.getItem("userLat")
    : 26.8634705;
  var lang = localStorage.getItem("userLang")
    ? localStorage.getItem("userLat")
    : 75.81852;
  const handelApplyFilter = (e) => {
    e.preventDefault();
    let data = {};

    if (activeDefaultTab == "meetup") {
      data = {
        type: "pickup",
        page: 1,
        sort: sortBy,
        direction: direction,
        minPrice: minPrice,
        maxPrice: maxPrice,
        distance: sliderDirection,
        coordinates: [parseFloat(lat), parseFloat(lang)],
        category: getCategoryId,
      };
    } else {
      data = {
        type: activeDefaultTab,
        page: 1,
        sort: sortBy,
        direction: direction,
        minPrice: minPrice,
        maxPrice: maxPrice,
        distance: sliderDirection,
        coordinates: [parseFloat(lat), parseFloat(lang)],
        category: getCategoryId,
      };
    }

    dispatch(fetchHomeProductList(data));
  };

  useEffect(() => {
    const request = {};
    dispatch(getCategoryList(request, returnCategory));
  }, [dispatch]);
  const [getCategoryData, setCategoryData] = useState("");
  const returnCategory = (data) => {
    setCategoryData(data);
  };
console.log(getCategoryData)

  const [getChildrenArray, setChildrenArray] = useState("");
  var dataArray = [];
  for (var z = 0; z < getCategoryData.length; z++) {
    for (var x = 0; x < getCategoryData[z].children.length; x++) {
      dataArray.push(getCategoryData[z].children[x]);
    }
  }

  const [getCategoryId, setCateID] = useState("");

  const handleChangeCategoryData = (event) => {
    setCateID(event.target.value);
  };

  return (
    <div>
      <>
        <section className="home_banner">
          <figure>
            <img src={banner} alt="NO_IMAGE" />
          </figure>
          <Container>
            <figcaption className="banner_capion">
              <h1>{t("BANNER_TITLE")}</h1>
              <p>{t("BANNER_TEXT")}</p>
              <Link to="#" className="btn theme_btn">
                {t("BANNER_BUTTON")}
              </Link>
            </figcaption>
          </Container>

          <div className="left_sticky">
            <ul>
              <li>
                <Link to="/manage">
                  <img src={Manage} alt="plus" />
                  <span>{t("RIGHT_SIDEBAR_MANAGE")}</span>
                </Link>
              </li>
              <li>
                <Link to="/create-post">
                  <img src={Plus} alt="plus" />
                  <span>{t("RIGHT_SIDEBAR_POST")}</span>
                </Link>
              </li>
              <li>
                <Link to="/chat">
                  <img src={Message} alt="message" />
                  <span>{t("RIGHT_SIDEBAR_MESSAGE")}</span>
                </Link>
              </li>
              <li>
                <Link to="/like">
                  <img src={Like} alt="link" />
                  <span>{t("RIGHT_SIDEBAR_LIKES")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </section>

        <section className="main_contant">
          <Container>
            <div className="tabs_outer">
              <div className="tab_outer_heading">
                <h2>{t("PRODUCT_HEAD_TITLE")}</h2>
              </div>

              <CustomTabs
                tabArr={customTab}
                defaultActiveKey={activeDefaultTab}
                onSelect={onSelectTab}
                homeActive={true}
                content={
                  <>
                    {LOADING ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <LottiLoader loader={true} height={150} width={150} />
                      </div>
                    ) : (
                      <>
                        <div className="tab_product_filter mb-4">
                          <h2>{t("FILTER_TITLE")}</h2>
                          {/* <Button className="theme_btn" onClick={slidetoggle}>
                              <img src={filterImg} alt="" />
                              Filter
                            </Button> */}
                          {!showReveal ? (
                            <Button
                              className="theme_btn"
                              onClick={() => setShowReveal(!showReveal)}
                            >
                              <img src={filterImg} alt="" />
                              {t("FILTER")}
                            </Button>
                          ) : (
                            <Button
                              className="theme_btn"
                              onClick={() => resetForm()}
                            >
                              <RotateLeftIcon /> {t("RESET")}
                            </Button>
                          )}
                        </div>

                        <Fade top when={showReveal}>
                          {showReveal && (
                            <div
                              style={{
                                background: "#f7f8fa",
                                padding: "39px 20px 18px 20px",
                                border: "1px dashed #f7f8fa",
                                marginBottom: "2rem",
                                marginTop: "2rem",
                              }}
                            >
                              <Form>
                                <Row>
                                  <Col lg={2} md={4} xs={12}>
                                    <Form.Group
                                      controlId="formBasicEmail"
                                      className="filter_product"
                                    >
                                      <Form.Label>{t("SORT_BY")}</Form.Label>
                                      <Form.Select
                                        aria-label="Default select example"
                                        onChange={(e) => handelChangeSortBy(e)}
                                        name="sort"
                                        //value={getSortBy}
                                      >
                                        <option value="">
                                          {t("SELECT_SORT_BY")}
                                        </option>
                                        <option value="newestToOldest">
                                          {t("NEWEST_TO_OLDEST")}
                                        </option>
                                        <option value="oldestToNewest">
                                          {t("OLDEST_TO_NEWEST")}
                                        </option>
                                        <option value="lowToHigh">
                                          {t("LOW_TO_HIGH")}
                                        </option>
                                        <option value="highToLow">
                                          {t("HIGH_TO_LOW")}
                                        </option>
                                        <option value="nearestToBuyer">
                                          {t("NEAREST_TO_BUYER")}
                                        </option>
                                        <option value="furthestToBuyer">
                                          {t("FURTHEST_TO_BUYER")}
                                        </option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={2} md={4} xs={12}>
                                    <Form.Group
                                      controlId="formBasicEmail"
                                      className="filter_product"
                                    >
                                      <Form.Label>
                                        {t("MIN_PRICE_IN_SR")}
                                      </Form.Label>

                                      <div className="d-flex w-100">
                                        <Form.Control
                                          type="text"
                                          placeholder="Enter price"
                                          style={{ height: "44px" }}
                                          value={minPrice}
                                          onChange={(e) =>
                                            setMinPrice(e.target.value)
                                          }
                                          onKeyPress={FloatInput}
                                          autoComplete="off"
                                        />
                                      </div>
                                    </Form.Group>
                                  </Col>

                                  <Col lg={2} md={4} xs={12}>
                                    <Form.Group
                                      controlId="formBasicEmail"
                                      className="filter_product"
                                    >
                                      <Form.Label>
                                        {t("MAX_PRICE_IN_SR")}
                                      </Form.Label>

                                      <div className="d-flex w-100">
                                        <Form.Control
                                          type="text"
                                          placeholder="Enter price"
                                          style={{ height: "44px" }}
                                          value={maxPrice}
                                          onChange={(e) =>
                                            setMaxPrice(e.target.value)
                                          }
                                          autoComplete="off"
                                          onKeyPress={FloatInput}
                                        />
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={2} md={4} xs={12}>
                                    <Form.Label>{t("DISTANCE")}</Form.Label>
                                    {/* <Form.Range /> */}
                                    <div className="px-3">
                                      <Slider
                                        defaultValue={sliderDirection}
                                        aria-label="Default"
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        onChange={(e, newValue) =>
                                          sliderChange(e, newValue)
                                        }
                                        // valueLabelDisplay="on"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={4} md={4} xs={12}>
                                    <Form.Label>{t("CATEGORY")}</Form.Label>
                                    {/* <Form.Range /> */}
                                    <Select
                                      native
                                      value={getCategoryId}
                                      label={t("CATEGORY")}
                                      className="w-100 searchOption cat-search form-control border-0"
                                      sx={{ height: "44px" }}
                                      inputProps={{
                                        name: "status",
                                        id: "outlined-age-native-simple",
                                      }}
                                      onChange={(e) =>
                                        handleChangeCategoryData(e)
                                      }
                                      variant="outlined"
                                      size={"small"}
                                    >
                                      <option aria-label="None" value="">
                                        {t("ALL")}
                                      </option>
                                      {dataArray &&
                                        dataArray.map((category, key) => (
                                          <option
                                            key={key}
                                            value={category._id}
                                          >
                                            {category.title}
                                          </option>
                                        ))}
                                    </Select>
                                  </Col>
                                  <Col
                                    lg={2}
                                    className="m-auto mt-4"
                                    md={4}
                                    xs={6}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      type="submit"
                                      className="theme_btn"
                                      style={{
                                        borderRadius: "0px",
                                      }}
                                      onClick={(e) => handelApplyFilter(e)}
                                    >
                                      {t("APPLY_FILTER_NEW")}
                                    </Button>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          )}
                        </Fade>

                        <div className="product_outer_main">
                          {!LOADING && GET_HOME_PRODUCT_LIST?.length === 0 ? (
                            <Row>
                              <LottiLoader
                                message="No products found"
                                height={250}
                                width={250}
                              />
                            </Row>
                          ) : (
                            <Row>
                              {GET_HOME_PRODUCT_LIST &&
                                GET_HOME_PRODUCT_LIST?.length !== 0 &&
                                GET_HOME_PRODUCT_LIST?.map((item) => {
                                  return (
                                    <Col
                                      lg={3}
                                      md={4}
                                      xs={6}
                                      key={item?.id}
                                      onClick={() =>
                                        history.push(`/product/${item?.id}`)
                                      }
                                    >
                                      <div className="product_card">
                                        <figure>
                                          <LazyLoadImage
                                            alt={item?.title}
                                            effect="blur"
                                            src={item?.image}
                                          />
                                        </figure>
                                        <div className="overlay_top">
                                          {item?.boost && (
                                            <span className="boost_badge">
                                              {t("DOFFO_BOOST")}
                                            </span>
                                          )}

                                          {item.sold && (
                                            <span className="sold_badge">
                                              {t("SOLD")}
                                            </span>
                                          )}
                                        </div>

                                        <div className="overlay_bottom">
                                          <h4>
                                            <a href="#!">{item?.title}</a>
                                          </h4>
                                          <div className="product_info">
                                            <span className="price">
                                              {TextFormate(item?.price, 2)} SR
                                            </span>
                                            {item?.shipping && (
                                              <span className="brand">
                                                <img
                                                  src={brnd}
                                                  alt="NO_IMAGE"
                                                />
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  );
                                })}
                            </Row>
                          )}
                        </div>
                      </>
                    )}
                  </>
                }
              />

              {LOAD_MORE_LOADING ? (
                <LottiLoader loader={true} height={120} width={120} />
              ) : (
                <>
                  {!LOADING &&
                    GET_HOME_PRODUCT_LIST?.length !== 0 &&
                    GET_HOME_PRODUCT_LIST.length !==
                      GET_HOME_PRODUCT_PAGINATION?.total && (
                      <Button
                        className="outline-btn theme_btn"
                        onClick={() => handelLoadMore()}
                      >
                        {t("LOAD_MORE_POST")}
                      </Button>
                    )}
                </>
              )}
            </div>
          </Container>
        </section>

        <section className="mobile_app">
          <Container>
            <div className="app_info_row">
              <div className="app_info">
                <h3>{t("TRY_DOFFO_APP")}</h3>
                <p>{t("DOFFO_APP_TEXT")}</p>
                <div className="app_detail">
                  <h5> {t("GET_APP_TODAY")}</h5>
                  <div className="installing_link">
                    <a href="#">
                      <img src="./assets/images/apple.png" />
                    </a>
                    <a href="#">
                      <img src="./assets/images/play.png" />
                    </a>
                  </div>
                </div>
              </div>

              <figure className="img_app_fream">
                <img src="./assets/images/app.png" />
              </figure>
            </div>
          </Container>
        </section>

        <div className="sidebar_overlay"></div>
      </>
    </div>
  );
}

export default Home;
